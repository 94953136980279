import { LeftArrowSVGV2, RightArrowSVGV2 } from "assets";
import React, { useState } from "react";
import { FeedbackCard } from "routes/Home/components";
import { FeedbackProps } from "../FeedbackCard/index";
import feedbackStyles from "./feedbackGroup.module.css";

// Feedback Props
export interface FeedbackGroupProps {
  customerFeedbacks: FeedbackProps[];
}

const FeedbackSection: React.FC<FeedbackGroupProps> = ({
  customerFeedbacks,
}) => {
  const [selectedFeedback, seSelectedFeedback] = useState(customerFeedbacks[0]);

  // Checks when to disable feedback button
  const isAtFirsFeedback =
    selectedFeedback.feedback === customerFeedbacks[0].feedback;
  const isAtLastFeedback =
    selectedFeedback.feedback ===
    customerFeedbacks[customerFeedbacks.length - 1].feedback;

  // Navigate feedback handler
  const viewFeedbackHandler = (action: "next" | "back") => {
    seSelectedFeedback((currentFeedback) => {
      const currentFeedBackIdx = customerFeedbacks.findIndex(
        (feedback) => feedback.feedback === selectedFeedback.feedback
      );

      if (
        action === "next" &&
        currentFeedBackIdx < customerFeedbacks.length - 1
      ) {
        return customerFeedbacks[currentFeedBackIdx + 1];
      } else if (action === "back" && currentFeedBackIdx > 0) {
        return customerFeedbacks[currentFeedBackIdx - 1];
      }

      return currentFeedback;
    });
  };

  return (
    <section className={`${feedbackStyles.feedbackSection} landingWrapper`}>
      <h1>What Vobb users are saying</h1>
      <div className={feedbackStyles.feedbackCards}>
        <FeedbackCard key={selectedFeedback.feedback} {...selectedFeedback} />
        <div className={feedbackStyles.feedbackNavArrow}>
          <LeftArrowSVGV2
            className={`${isAtFirsFeedback ? feedbackStyles.disabled : ""}`}
            onClick={() => viewFeedbackHandler("back")}
          />
          <RightArrowSVGV2
            className={`${isAtLastFeedback ? feedbackStyles.disabled : ""}`}
            onClick={() => viewFeedbackHandler("next")}
          />
        </div>
      </div>
    </section>
  );
};

export default FeedbackSection;
