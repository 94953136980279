import {
  DeleteIcon,
  PlusIcon,
  DocumentSketchIcon,
  WarningIcon,
  DocUploadIcon,
  DummyAvatar,
  VobbLogoFull,
} from "assets";
import * as React from "react";
import { useParams } from "react-router-dom";
import {
  countryOptions,
  educationHistoryOptions,
  genderOptions,
  languageQualificationOptions,
  maritalOptions,
  nationalityOptions,
} from "utils/options";
import styles from "./styles.module.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useForm,
  SubmitHandler,
  useFieldArray,
  SubmitErrorHandler,
} from "react-hook-form";
import { Input } from "components/Input";
import { CustomSelect } from "components/Select";
import { handleEnter } from "Helper/handleEnter";
import Toast from "components/Toast/toast";
import axios from "requests/axios-base";
import {
  GeneralInfo,
  initialGeneralInfo,
  EducationInfo,
  initialEducationHistory,
  LanguageInfo,
  initialLanguageData,
  Documents,
  initialDocuments,
  doc,
  initDoc,
  initDocsUpload,
  uploadDocsType,
} from "./types";
import Preloader from "components/Preloader/preloader";

const optionTypeSchemaReq = yup.object({
  label: yup.string().required("Required"),
  value: yup.string().required("Required"),
});

const generalInfoSchema = yup
  .object({
    passportPhoto: yup.string(),
    firstName: yup.string().required("Required"),
    middleName: yup.string(),
    lastName: yup.string().required("Required"),
    gender: yup.string().required("Required"),
    passportNo: yup
      .string()
      .required("Required")
      .matches(/[0-9a-zA-Z]+$/, "Only alphanumeric characters are expected"),
    dateofBirth: yup.string().required("Required"),
    maritalStatus: yup.string().required("Required"),
    fathersName: yup.string(),
    mothersName: yup.string(),
    issueDate: yup.date().required("Required"),
    expiryDate: yup.string().required("Required"),
    address: yup.string().required("Required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    email: yup.string().email("Enter a valid email").required("Required"),
    phone: yup.string().required("Required"),
    nationality: optionTypeSchemaReq,
    country: optionTypeSchemaReq,
    countryOfResidence: optionTypeSchemaReq,
    birthPlace: yup.string().required("Required"),
  })
  .required();

const educationHistorySchema = yup
  .object({
    eduHistory: yup.array().of(
      yup.object({
        level: optionTypeSchemaReq,
        program: yup.string().required("Required"),
        institute: yup.string().required("Required"),
        startDate: yup.string().required("Required"),
        endDate: yup.string().required("Required"),
        country: optionTypeSchemaReq,
        studyLanguage: yup.string().required("Required"),
        grade: yup.string().required("Required"),
        maximumGrade: yup.string().required("Required"),
      })
    ),
  })
  .required();

const languageSchema = yup
  .object({
    languages: yup.array().of(
      yup.object({
        qualification: optionTypeSchemaReq,
        band: yup.string().required("Required"),
        examDate: yup.string().required("Required"),
        readingScore: yup.string().required("Required"),
        listeningScore: yup.string().required("Required"),
        writingScore: yup.string().required("Required"),
        speakingScore: yup.string().required("Required"),
      })
    ),
  })
  .required();

const documentSchema = yup
  .object({
    intlPassport: yup.mixed().nullable(),
    highSchoolTranscript: yup.mixed().nullable(),
    languageCert: yup.mixed().nullable(),
    workExperience: yup.mixed().nullable(),
    previousVisa: yup.mixed().nullable(),
    highSchoolCert: yup.mixed().nullable(),
    statementOfPurpose: yup.mixed().nullable(),
    undergradTranscript: yup.mixed().nullable(),
    undergradCert: yup.mixed().nullable(),
    referenceLetters: yup
      .array()
      .of(yup.mixed().nullable().required("Required")),
    others: yup.array().of(yup.mixed().nullable().required("Required")),
  })
  .required();

const RegisterAsProspect = () => {
  const params: { id: string } = useParams();
  const [stage, setStage] = React.useState(1);
  const [photo, setPhoto] = React.useState({
    nameUrl: "",
    file: new File([], ""),
  });
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [uploadDocs, setUploadDocs] =
    React.useState<uploadDocsType>(initDocsUpload);
  const [hasUploaded, setHasUploaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [metReq, setMetReq] = React.useState(false);

  const {
    register: registerGeneral,
    handleSubmit: handleSubmitGeneral,
    setValue: setValueGeneral,
    formState: { errors: errorsGeneral },
    watch: watchGeneral,
    getValues: getValuesGeneral,
    reset: resetGeneral,
  } = useForm<GeneralInfo>({
    resolver: yupResolver(generalInfoSchema),
    defaultValues: initialGeneralInfo,
  });

  const {
    register: registerEducation,
    handleSubmit: handleSubmitEducation,
    setValue: setValueEducation,
    formState: { errors: errorsEducation },
    watch: watchEducation,
    control: controlEducation,
    getValues: getValuesEducation,

    clearErrors,
    reset: resetEducation,
  } = useForm<EducationInfo>({
    resolver: yupResolver(educationHistorySchema),
    defaultValues: { eduHistory: [{ ...initialEducationHistory }] },
  });

  const {
    fields: fieldsEdu,
    append: appendEdu,
    remove: removeEdu,
  } = useFieldArray({
    control: controlEducation,
    name: "eduHistory",
  });

  const {
    register: registerLanguage,
    handleSubmit: handleSubmitLanguage,
    setValue: setValueLanguage,
    formState: { errors: errorsLanguage },
    watch: watchLanguage,
    control: controlLanguage,
    getValues: getValuesLanguage,
    clearErrors: clearErrorsLanguage,
    reset: resetLanguage,
  } = useForm<LanguageInfo>({
    resolver: yupResolver(languageSchema),
    defaultValues: { languages: [{ ...initialLanguageData }] },
  });

  const {
    fields: fieldsLang,
    append: appendLang,
    remove: removeLang,
  } = useFieldArray({
    control: controlLanguage,
    name: "languages",
  });

  const {
    register: registerDocuments,
    handleSubmit: handleSubmitDocuments,
    setValue: setValueDocuments,
    formState: { errors: errorsDocuments },
    watch: watchDocuments,
    getValues: getValuesDocuments,
    reset: resetDocuments,
  } = useForm<Documents>({
    resolver: yupResolver(documentSchema),
    defaultValues: initialDocuments,
  });

  // Minimum of 10 years
  const formatYear = new Date().getFullYear() - 10;
  const maxDate = `${formatYear}-${12}-${31}`;

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const fileName = `Passport photo.${getExtension(file.name)}`;
    const photo = new File([file], fileName);

    setPhoto({ file: photo, nameUrl: URL?.createObjectURL(file) });
    setValueGeneral("passportPhoto", file);
  };

  const onSubmitGeneral: SubmitHandler<GeneralInfo> = (data) => {
    setMetReq(true);
    setStage(2);
  };

  const addEducation: SubmitHandler<EducationInfo> = (data) => {
    appendEdu({ ...initialEducationHistory });
  };

  const isValidArrayOfObjects2 = (arr): boolean => {
    return (
      arr.every((item) => {
        return Object.keys(item).every((key) => item[key] !== "");
      }) ||
      arr.every((item) => {
        return Object.keys(item).every((key) => item[key] === "");
      })
    );
  };

  const onSubmitEducation: SubmitHandler<EducationInfo> = (data) => {
    setStage(3);
  };

  const onSubmitEducationError: SubmitErrorHandler<EducationInfo> = (data) => {
    const { eduHistory } = watchEducation();

    const edu = eduHistory.map((item) => ({
      level: item.level.value,
      insititute: item.institute,
      program: item.program,
      startDate: item.startDate,
      endDate: item.endDate,
      country: item.country.value,
      language: item.studyLanguage,
      aveGrade: item.grade,
      maxGrade: item.maximumGrade,
    }));

    if (isValidArrayOfObjects2(edu)) {
      clearErrors();
      setStage(3);
    }
  };

  const getMessage = ({
    value,
    errorList,
    index,
    key,
  }: {
    value: any;
    errorList: any[] | undefined;
    index: number;
    key: string;
  }) => {
    let item = errorList ? errorList[index] : [];

    return item ? (value ? "" : item[key]?.value?.message ?? "") : "";
  };

  const addLanguage: SubmitHandler<LanguageInfo> = (data) => {
    appendLang({ ...initialLanguageData });
  };

  const onSubmitLanguage: SubmitHandler<LanguageInfo> = (data) => {
    setStage(4);
  };

  const onSubmitLanguageError: SubmitErrorHandler<LanguageInfo> = (data) => {
    const { languages } = getValuesLanguage();

    const lang = languages.map((item) => ({
      qualification: item.qualification.value,
      band: item.band,
      examDate: item.examDate,
      readingScore: item.readingScore,
      writingScore: item.writingScore,
      speakingScore: item.speakingScore,
      listeningScore: item.listeningScore,
    }));

    if (isValidArrayOfObjects2(lang)) {
      clearErrorsLanguage();
      setStage(4);
    }
  };

  const getMegaByte = (num) => {
    return num ? (Number(num) / 1000000).toFixed(3) : 0;
  };

  const getFileName = (fileName) => {
    return fileName.split(".").at(0);
  };

  const getExtension = (fileName) => {
    return fileName.split(".").at(-1);
  };

  const checkFileSize = ({
    file,
    onSuccess,
  }: {
    file: any;
    onSuccess: () => void;
  }) => {
    if (file.size <= 1048576 * 5) {
      onSuccess();
    } else {
      tooLarge();
    }
  };

  const tooLarge = () => {
    setToast({
      show: true,
      heading: "File size error",
      text: "Failed to attach file greater than 5MB. Please reduce size and try again.",
      type: false,
    });
  };

  const handleDocUpload = (e: any, name, key) => {
    const file = e.target.files[0];
    const fileName = `${name}.${getExtension(file.name)}`;
    const doc = new File([file], fileName);

    checkFileSize({
      file: file,
      onSuccess: () => {
        setValueDocuments(key, doc);
        setHasUploaded(false);
      },
    });
  };

  const handleRefUpload = (e: any, index: number) => {
    const prevList = watchDocuments("referenceLetters");

    const file = e.target.files[0];
    const name = `${getFileName(file.name)} VobbRef.${getExtension(file.name)}`;
    const doc = new File([file], name);

    const newList = prevList.map((item, idx) => (idx === index ? doc : item));

    checkFileSize({
      file: file,
      onSuccess: () => {
        setValueDocuments("referenceLetters", newList);
        setHasUploaded(false);
      },
    });
  };

  const handleOtherUpload = (e: any, index: number) => {
    const prevList = watchDocuments("others");

    const file = e.target.files[0];
    const name = `${getFileName(file.name)} VobbOther.${getExtension(
      file.name
    )}`;
    const doc = new File([file], name);

    const newList = prevList.map((item, idx) => (idx === index ? doc : item));

    checkFileSize({
      file: file,
      onSuccess: () => {
        setValueDocuments("others", newList);
        setHasUploaded(false);
      },
    });
  };

  const onSubmitDocument: SubmitHandler<Documents> = (data) => {
    uploadDocuments();
    // setPay(true);
  };

  const eduCertDocs: doc[] = [
    {
      label: "High School Transcript",
      fileName: "High School Transcript",
      key: "highSchoolTranscript",
    },
    {
      label: "High School Certificate",
      fileName: "High School Certificate",
      key: "highSchoolCert",
    },
    {
      label: "Undergraduate Transcript (If applying for PG)",
      fileName: "Undergraduate Transcript",
      key: "undergradTranscript",
    },
    {
      label: "Undergraduate Certificate (if applying for PG)",
      fileName: "Undergraduate Certificate",
      key: "undergradCert",
    },
    {
      label: "IELTS Copy/Other Language Proficiency",
      fileName: "Language Certificate",
      key: "languageCert",
    },
  ];

  const personalDocs: doc[] = [
    {
      label: "Valid Passport Copy",
      fileName: "Intl. Passport",
      key: "intlPassport",
    },

    {
      label: "Previous Visa Copies if existing",
      fileName: "Previous Visa",
      key: "previousVisa",
    },
    {
      label: "Statement of Purpose",
      fileName: "Statement of Purpose",
      key: "statementOfPurpose",
    },
  ];

  const workDocs: doc[] = [
    {
      label: "CV & Work Experience Certificate",
      fileName: "Work Experience",
      key: "workExperience",
    },
  ];

  const ref: doc = {
    label: "Recommendation/Reference Letter",
    fileName: "Reference Letter",
    key: "referenceLetters",
  };

  const other: doc = {
    label: "Document",
    fileName: "Others",
    key: "others",
  };

  const uploadDocuments = () => {
    setLoading(true);

    const docKeys = Object.keys(getValuesDocuments());
    const docKeysUpload = docKeys.filter((key) =>
      key === "referenceLetters"
        ? getValuesDocuments()[key].filter((item) => item.size > 0).length > 0
        : getValuesDocuments()[key] !== null &&
          getValuesDocuments()[key].size > 0
    );

    if ((docKeysUpload.length > 0 || photo.file) && !hasUploaded) {
      let refData = getValuesDocuments().referenceLetters.map((item) => ({
        file: item,
        name: item.name,
      }));

      let docData = docKeysUpload
        .map((key) =>
          key === "referenceLetters"
            ? refData
            : {
                file: getValuesDocuments()[key],
                name: getValuesDocuments()[key].name,
              }
        )
        .flat();

      let data = new FormData();
      docData.forEach((file) => {
        data.append(`files`, file.file, file.name);
      });

      if (photo.file.size > 0 && photo.nameUrl) {
        data.append(`files`, photo.file, photo.file.name);
      }

      axios
        .post(`documnet-upload`, data)
        .then((res) => {
          setHasUploaded(true);

          const data: any[] = res.data.data;

          const intlPassport =
            data.find((item) => /Intl. Passport/.test(item.originalname)) ??
            initDoc;
          const previousVisa =
            data.find((item) => /Previous Visa/.test(item.originalname)) ??
            initDoc;
          const sop =
            data.find((item) =>
              /Statement of Purpose/.test(item.originalname)
            ) ?? initDoc;
          const hsCert =
            data.find((item) =>
              /High School Certificate/.test(item.originalname)
            ) ?? initDoc;
          const hsTranscript =
            data.find((item) =>
              /High School Transcript/.test(item.originalname)
            ) ?? initDoc;
          const undegradCert =
            data.find((item) =>
              /Undergraduate Certificate/.test(item.originalname)
            ) ?? initDoc;
          const undegradTranscript =
            data.find((item) =>
              /Undergraduate Transcript/.test(item.originalname)
            ) ?? initDoc;
          const languageCert =
            data.find((item) =>
              /Language Certificate/.test(item.originalname)
            ) ?? initDoc;
          const workExperience =
            data.find((item) => /Work Experience/.test(item.originalname)) ??
            initDoc;
          const reference = data.filter((item) =>
            /VobbRef/.test(item.originalname)
          );
          const others = data.filter((item) =>
            /VobbOther/.test(item.originalname)
          );
          const passportPhoto =
            data.find((item) => /Passport photo/.test(item.originalname)) ??
            initDoc;

          const docs: uploadDocsType = {
            passId: {
              url: passportPhoto.location,
              key: passportPhoto.key,
              filename: passportPhoto.originalname,
            },
            passCopy: {
              url: intlPassport.location,
              key: intlPassport.key,
              filename: intlPassport.originalname,
            },
            hst: {
              url: hsTranscript.location,
              key: hsTranscript.key,
              filename: hsTranscript.originalname,
            },
            languageProficiency: {
              url: languageCert.location,
              key: languageCert.key,
              filename: languageCert.originalname,
            },
            cv: {
              url: workExperience.location,
              key: workExperience.key,
              filename: workExperience.originalname,
            },
            visa: {
              url: previousVisa.location,
              key: previousVisa.key,
              filename: previousVisa.originalname,
            },
            hsc: {
              url: hsCert.location,
              key: hsCert.key,
              filename: hsCert.originalname,
            },
            sop: {
              url: sop.location,
              key: sop.key,
              filename: sop.originalname,
            },
            reference: reference.map((item) => ({
              url: item.location,
              key: item.key,
              filename: item.originalname,
            })),
            others: others.map((item) => ({
              url: item.location,
              key: item.key,
              filename: item.originalname,
            })),
            undergraduateTranscript: {
              url: undegradTranscript.location,
              key: undegradTranscript.key,
              filename: undegradTranscript.originalname,
            },
            undergraduateCertificate: {
              url: undegradCert.location,
              key: undegradCert.key,
              filename: undegradCert.originalname,
            },
          };

          setUploadDocs(docs);
          createProspect(docs);
        })
        .catch((err) => {
          setToast({
            show: true,
            heading: "Unable to upload student documents",
            text: err?.response?.data?.message,
            type: false,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createProspect(hasUploaded ? uploadDocs : initDocsUpload);
    }
  };

  const isValidArrayOfObjects = (arr) => {
    return arr.every((item) => {
      return Object.keys(item).every((key) => item[key] !== "");
    });
  };

  const isValidEduArray = (arr) => {
    return arr.every((item) => {
      return Object.keys(item).every((key) =>
        item.level === "ssce"
          ? key === "aveGrade" || key === "program" || key === "maxGrade"
            ? item[key] === ""
            : item[key] !== ""
          : item[key] !== ""
      );
    });
  };

  const createProspect = (docs: uploadDocsType) => {
    const general = getValuesGeneral();
    const { eduHistory } = getValuesEducation();
    const { languages } = getValuesLanguage();

    const lang = languages.map((item) => ({
      qualification: item.qualification.value,
      band: item.band,
      examDate: item.examDate,
      readingScore: item.readingScore,
      writingScore: item.writingScore,
      speakingScore: item.speakingScore,
      listeningScore: item.listeningScore,
    }));

    const edu = eduHistory.map((item) => ({
      level: item.level.value,
      insititute: item.institute,
      program: item.program === "nil" ? "" : item.program,
      startDate: item.startDate,
      endDate: item.endDate,
      country: item.country.value,
      language: item.studyLanguage,
      aveGrade: item.grade === "nil" ? "" : item.grade,
      maxGrade: item.maximumGrade === "nil" ? "" : item.maximumGrade,
    }));

    const data = {
      firstName: general.firstName,
      lastName: general.lastName,
      middleName: general.middleName,
      dob: general.dateofBirth,
      gender: general.gender,
      maritalStatus: general.maritalStatus,
      fName: general.fathersName,
      mName: general.mothersName,
      nationality: general.nationality.value,
      passport: {
        number: general.passportNo,
        issueDate: general.issueDate,
        expiryDate: general.expiryDate,
        issueCountry: general.country.value,
      },
      placeOfBirth: general.birthPlace,
      countryOfBirth: general.country.value,
      language: isValidArrayOfObjects(lang) ? lang : [],
      contactInfo: {
        address: general.address,
        city: general.city,
        state: general.state,
        country: general.countryOfResidence.value,
        email: general.email,
        phone: general.phone,
      },
      education: isValidEduArray(edu) ? edu : [],
      suppDocs: docs,
    };

    axios
      .post(`create-prospect-external-link/${params.id ?? ""}`, data)
      .then((res) => {
        if (res.status === 200) {
          // Reset form
          resetDocuments();
          resetEducation();
          resetGeneral();
          resetLanguage();
          setStage(1);
          setPhoto({
            nameUrl: "",
            file: new File([], ""),
          });

          setHasUploaded(false);
          setToast({
            show: true,
            heading: "That's all!",
            text: "You've successfully created your student profile",
            type: true,
          });
        }
      })
      .catch((err) => {
        setToast({
          show: true,
          heading: "Unable to create student",
          text: err.response.data.message,
          type: false,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Toast
        {...toast}
        closeModal={() => setToast({ ...toast, show: false })}
      />
      {loading ? <Preloader /> : ""}

      <main className={`${styles.container} appContainer`}>
        <nav className={styles.ctrlSec}>
          <div
            role="button"
            onClick={() => setStage(1)}
            className={stage === 1 ? styles.ctrlActive : ""}
          >
            Your Bio
          </div>
          <div
            role="button"
            onClick={() => (metReq ? setStage(2) : "")}
            className={`${stage === 2 ? styles.ctrlActive : ""} ${
              !metReq ? styles.disabledCtrl : ""
            }`}
          >
            Education
          </div>
          <div
            role="button"
            onClick={() => (metReq ? setStage(3) : "")}
            className={`${stage === 3 ? styles.ctrlActive : ""} ${
              !metReq ? styles.disabledCtrl : ""
            }`}
          >
            Language
          </div>
          <div
            role="button"
            onClick={() => (metReq ? setStage(4) : "")}
            className={`${stage === 4 ? styles.ctrlActive : ""} ${
              !metReq ? styles.disabledCtrl : ""
            }`}
          >
            Documents
          </div>
        </nav>

        <div className={styles.formContainer}>
          <h1 className={styles.formHeading}>
            {stage === 1
              ? "Your Student Bio"
              : stage === 2
              ? "Your Education History"
              : stage === 3
              ? "Your Language Qualifications"
              : "Your Documents"}
          </h1>
          <p>
            {stage === 1
              ? "Fill out your basic information to being your application process. This should only take a few minutes; be sure to enter correct information like your Name, and dates. It is preferred that you also provide a recent passport photograph below."
              : stage === 2
              ? "In order to ensure that you are matched with the most suitable university programs, we kindly ask that you provide us with your education history. Please provide detailed information about your past education, including the name of the institutions attended, the level of education achieved."
              : stage === 3
              ? "Some universities require all applicants to provide information about their English proficiency qualifications to ensure that they are equipped to fully participate in their classes and activities. Please provide them if you have any. Some of the qualification we accept include IELTS, TOEFL, and more."
              : "Finally! Your Supporting documents are a crucial part of your application and are used to evaluate your eligibility for the program. Please ensure that you submit any available documents in their accepted format, and that they are clearly legible."}
          </p>

          {stage === 1 ? (
            <form className={styles.formWrap}>
              <p className={styles.formTtl}>Personal Information</p>
              <div className={styles.avatarSec}>
                <img
                  className={styles.avatar}
                  src={photo.nameUrl !== "" ? photo.nameUrl : DummyAvatar}
                  alt="avatar"
                />
                <label
                  role="button"
                  htmlFor="passportPhoto"
                  className={styles.changeBtn}
                >
                  <input
                    onChange={handleImageUpload}
                    name="passportPhoto"
                    id="passportPhoto"
                    type={"file"}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                  Upload passport photo
                </label>
                {!watchGeneral("passportPhoto") &&
                errorsGeneral.passportPhoto?.message ? (
                  <p className={styles.errorMsg}>
                    <WarningIcon /> {errorsGeneral.passportPhoto?.message}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <Input
                label="First name*"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.firstName?.message}
                name="firstName"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("firstName")}
              />
              <Input
                label="Middle name"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.middleName?.message}
                name="middleName"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("middleName")}
              />
              <Input
                label="Last name*"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.lastName?.message}
                name="lastName"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("lastName")}
              />
              <CustomSelect
                onChange={(x) => setValueGeneral("nationality", x)}
                validatorMessage={
                  watchGeneral("nationality").value
                    ? ""
                    : errorsGeneral.nationality?.value?.message ?? ""
                }
                inputClass={styles.select}
                name={"nationality"}
                placeholder={"Select..."}
                label={"Nationality*"}
                options={nationalityOptions}
                value={watchGeneral("nationality")}
                parentClassName={styles.inputWrap}
              />
              <Input
                label="Date of birth*"
                placeholder=""
                type="date"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.dateofBirth?.message}
                name="dateofBirth"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("dateofBirth")}
                maxDate={maxDate}
              />
              <CustomSelect
                onChange={(x) => setValueGeneral("gender", x.value)}
                validatorMessage={
                  watchGeneral("gender")
                    ? ""
                    : errorsGeneral.gender?.message ?? ""
                }
                inputClass={styles.select}
                name={"gender"}
                placeholder={"Select..."}
                label={"Gender*"}
                options={genderOptions}
                value={{
                  label: watchGeneral("gender"),
                  value: watchGeneral("gender"),
                }}
                parentClassName={styles.inputWrap}
              />
              <CustomSelect
                onChange={(x) => setValueGeneral("maritalStatus", x.value)}
                validatorMessage={
                  watchGeneral("maritalStatus")
                    ? ""
                    : errorsGeneral.maritalStatus?.message ?? ""
                }
                inputClass={styles.select}
                name={"maritalStatus"}
                placeholder={"Select..."}
                label={"Marital status*"}
                options={maritalOptions}
                value={{
                  label: watchGeneral("maritalStatus"),
                  value: watchGeneral("maritalStatus"),
                }}
                parentClassName={styles.inputWrap}
              />
              <Input
                label="Fathers name"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.fathersName?.message}
                name="fathersName"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("fathersName")}
              />
              <Input
                label="Mothers name"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.mothersName?.message}
                name="mothersName"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("mothersName")}
              />
              <p className={styles.formTtl}>
                International Passport Information
              </p>
              <Input
                label="Passport number*"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.passportNo?.message}
                name="passportNo"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("passportNo")}
              />
              <Input
                label="Issue date*"
                placeholder=""
                type="date"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.issueDate?.message}
                name="issueDate"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("issueDate")}
              />
              <Input
                label="Expiry date*"
                placeholder=""
                type="date"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.expiryDate?.message}
                name="expiryDate"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("expiryDate")}
              />
              <CustomSelect
                onChange={(x) => setValueGeneral("country", x)}
                validatorMessage={
                  watchGeneral("country").value
                    ? ""
                    : errorsGeneral.country?.value?.message ?? ""
                }
                inputClass={styles.select}
                name={"country"}
                placeholder={"Select..."}
                label={"Country*"}
                options={countryOptions}
                value={watchGeneral("country")}
                parentClassName={styles.inputWrap}
              />
              <Input
                label="Place of birth*"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.birthPlace?.message}
                name="birthPlace"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("birthPlace")}
              />

              <div className={styles.inputWrap}></div>

              <p className={styles.formTtl}>Contact Information</p>
              <Input
                label="Address*"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.address?.message}
                name="address"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("address")}
              />
              <Input
                label="City*"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.city?.message}
                name="city"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("city")}
              />
              <Input
                label="State*"
                placeholder=""
                type="text"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.state?.message}
                name="state"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("state")}
              />
              <CustomSelect
                onChange={(x) => setValueGeneral("countryOfResidence", x)}
                validatorMessage={
                  watchGeneral("countryOfResidence").value
                    ? ""
                    : errorsGeneral.countryOfResidence?.value?.message ?? ""
                }
                inputClass={styles.select}
                name={"countryOfResidence"}
                placeholder={"Select..."}
                label={"Country of residence*"}
                options={countryOptions}
                value={watchGeneral("countryOfResidence")}
                parentClassName={styles.inputWrap}
              />
              <Input
                label="Email*"
                placeholder=""
                type="email"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.email?.message}
                name="email"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("email")}
              />
              <Input
                label="Phone*"
                placeholder=""
                type="phone"
                className={styles.input}
                required
                validatorMessage={errorsGeneral.phone?.message}
                name="phone"
                register={registerGeneral}
                onKeyDown={handleEnter}
                parentClassName={styles.inputWrap}
                value={watchGeneral("phone")}
              />
              <button
                onClick={handleSubmitGeneral(onSubmitGeneral)}
                className={styles.nextBtn}
              >
                Next
              </button>
            </form>
          ) : stage === 2 ? (
            <form className={styles.formWrap}>
              {fieldsEdu.map((item, index) => {
                return (
                  <div className={styles.subFormWrap} key={item.id}>
                    <p className={styles.formTtl}>Education ({index + 1})</p>

                    {fieldsEdu.length > 1 && (
                      <button
                        className={styles.removeBtn}
                        type="button"
                        onClick={() => removeEdu(index)}
                      >
                        <DeleteIcon />
                      </button>
                    )}

                    <CustomSelect
                      onChange={(x) => {
                        setValueEducation(`eduHistory.${index}.level`, x);

                        if (x.value === "ssce") {
                          setValueEducation(
                            `eduHistory.${index}.program`,
                            "nil"
                          );
                          setValueEducation(`eduHistory.${index}.grade`, "nil");
                          setValueEducation(
                            `eduHistory.${index}.maximumGrade`,
                            "nil"
                          );
                        }
                      }}
                      validatorMessage={getMessage({
                        value: watchEducation(`eduHistory.${index}.level`)
                          .value,
                        errorList: errorsEducation?.eduHistory,
                        index: index,
                        key: "level",
                      })}
                      inputClass={styles.select}
                      name={`eduHistory.${index}.level`}
                      placeholder={"Select..."}
                      label={"Education level"}
                      options={educationHistoryOptions}
                      value={watchEducation(`eduHistory.${index}.level`)}
                      parentClassName={styles.inputWrap}
                    />
                    <Input
                      label="Institute"
                      placeholder=""
                      type="text"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsEducation?.eduHistory
                          ? errorsEducation?.eduHistory[index]?.institute
                              ?.message ?? ""
                          : ""
                      }
                      name={`eduHistory.${index}.institute`}
                      register={registerEducation}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchEducation(`eduHistory.${index}.institute`)}
                    />
                    {watchEducation(`eduHistory.${index}.level`).value !==
                    "ssce" ? (
                      <Input
                        label="Program"
                        placeholder=""
                        type="text"
                        className={styles.input}
                        required
                        validatorMessage={
                          errorsEducation?.eduHistory
                            ? errorsEducation?.eduHistory[index]?.program
                                ?.message ?? ""
                            : ""
                        }
                        name={`eduHistory.${index}.program`}
                        register={registerEducation}
                        onKeyDown={handleEnter}
                        parentClassName={styles.inputWrap}
                        value={watchEducation(`eduHistory.${index}.program`)}
                      />
                    ) : (
                      ""
                    )}
                    <Input
                      label="Start date"
                      placeholder=""
                      type="date"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsEducation?.eduHistory
                          ? errorsEducation?.eduHistory[index]?.startDate
                              ?.message ?? ""
                          : ""
                      }
                      name={`eduHistory.${index}.startDate`}
                      register={registerEducation}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchEducation(`eduHistory.${index}.startDate`)}
                    />
                    <Input
                      label="Expected end date"
                      placeholder=""
                      type="date"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsEducation?.eduHistory
                          ? errorsEducation?.eduHistory[index]?.endDate
                              ?.message ?? ""
                          : ""
                      }
                      name={`eduHistory.${index}.endDate`}
                      register={registerEducation}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchEducation(`eduHistory.${index}.endDate`)}
                    />
                    <CustomSelect
                      onChange={(x) =>
                        setValueEducation(`eduHistory.${index}.country`, x)
                      }
                      validatorMessage={getMessage({
                        value: watchEducation(`eduHistory.${index}.country`)
                          .value,
                        errorList: errorsEducation?.eduHistory,
                        index: index,
                        key: "country",
                      })}
                      inputClass={styles.select}
                      name={`eduHistory.${index}.country`}
                      placeholder={"Select..."}
                      label={"Country"}
                      options={countryOptions}
                      value={watchEducation(`eduHistory.${index}.country`)}
                      parentClassName={styles.inputWrap}
                    />
                    <Input
                      label="Study language"
                      placeholder=""
                      type="text"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsEducation?.eduHistory
                          ? errorsEducation?.eduHistory[index]?.studyLanguage
                              ?.message ?? ""
                          : ""
                      }
                      name={`eduHistory.${index}.studyLanguage`}
                      register={registerEducation}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchEducation(
                        `eduHistory.${index}.studyLanguage`
                      )}
                    />
                    {watchEducation(`eduHistory.${index}.level`).value !==
                    "ssce" ? (
                      <>
                        {" "}
                        <Input
                          label="GPA"
                          placeholder=""
                          type="number"
                          className={styles.input}
                          required
                          validatorMessage={
                            errorsEducation?.eduHistory
                              ? errorsEducation?.eduHistory[index]?.grade
                                  ?.message ?? ""
                              : ""
                          }
                          name={`eduHistory.${index}.grade`}
                          register={registerEducation}
                          onKeyDown={handleEnter}
                          parentClassName={styles.inputWrap}
                          value={watchEducation(`eduHistory.${index}.grade`)}
                        />
                        <Input
                          label="Maximum GPA possible"
                          placeholder=""
                          type="number"
                          className={styles.input}
                          required
                          validatorMessage={
                            errorsEducation?.eduHistory
                              ? errorsEducation?.eduHistory[index]?.maximumGrade
                                  ?.message ?? ""
                              : ""
                          }
                          name={`eduHistory.${index}.maximumGrade`}
                          register={registerEducation}
                          onKeyDown={handleEnter}
                          parentClassName={styles.inputWrap}
                          value={watchEducation(
                            `eduHistory.${index}.maximumGrade`
                          )}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
              <div style={{ width: "100%" }}>
                <button
                  className={styles.addBtn}
                  onClick={handleSubmitEducation(addEducation)}
                >
                  <PlusIcon /> Add New
                </button>
              </div>

              <button
                onClick={handleSubmitEducation(
                  onSubmitEducation,
                  onSubmitEducationError
                )}
                className={styles.nextBtn}
              >
                Next
              </button>
            </form>
          ) : stage === 3 ? (
            <form className={styles.formWrap}>
              {fieldsLang.map((item, index) => {
                return (
                  <div className={styles.subFormWrap} key={item.id}>
                    <p className={styles.formTtl}>
                      Language Qualification ({index + 1})
                    </p>
                    {index !== 0 && (
                      <button
                        className={styles.removeBtn}
                        type="button"
                        onClick={() => removeLang(index)}
                      >
                        <DeleteIcon />
                      </button>
                    )}

                    <CustomSelect
                      onChange={(x) =>
                        setValueLanguage(`languages.${index}.qualification`, x)
                      }
                      validatorMessage={getMessage({
                        value: watchLanguage(`languages.${index}.qualification`)
                          .value,
                        errorList: errorsLanguage?.languages,
                        index: index,
                        key: "qualification",
                      })}
                      inputClass={styles.select}
                      name={`languages.${index}.qualification`}
                      placeholder={"Select..."}
                      label={"Qualification"}
                      options={languageQualificationOptions}
                      value={watchLanguage(`languages.${index}.qualification`)}
                      parentClassName={styles.inputWrap}
                    />

                    <Input
                      label="Band (total score)"
                      placeholder=""
                      type="number"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsLanguage?.languages
                          ? errorsLanguage?.languages[index]?.band?.message ??
                            ""
                          : ""
                      }
                      name={`languages.${index}.band`}
                      register={registerLanguage}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchLanguage(`languages.${index}.band`)}
                    />
                    <Input
                      label="Exam date"
                      placeholder=""
                      type="date"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsLanguage?.languages
                          ? errorsLanguage?.languages[index]?.examDate
                              ?.message ?? ""
                          : ""
                      }
                      name={`languages.${index}.examDate`}
                      register={registerLanguage}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchLanguage(`languages.${index}.examDate`)}
                    />
                    <Input
                      label="Reading score"
                      placeholder=""
                      type="number"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsLanguage?.languages
                          ? errorsLanguage?.languages[index]?.readingScore
                              ?.message ?? ""
                          : ""
                      }
                      name={`languages.${index}.readingScore`}
                      register={registerLanguage}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchLanguage(`languages.${index}.readingScore`)}
                    />
                    <Input
                      label="Listening score"
                      placeholder=""
                      type="number"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsLanguage?.languages
                          ? errorsLanguage?.languages[index]?.listeningScore
                              ?.message ?? ""
                          : ""
                      }
                      name={`languages.${index}.listeningScore`}
                      register={registerLanguage}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchLanguage(`languages.${index}.listeningScore`)}
                    />
                    <Input
                      label="Writing score"
                      placeholder=""
                      type="number"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsLanguage?.languages
                          ? errorsLanguage?.languages[index]?.writingScore
                              ?.message ?? ""
                          : ""
                      }
                      name={`languages.${index}.writingScore`}
                      register={registerLanguage}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchLanguage(`languages.${index}.writingScore`)}
                    />
                    <Input
                      label="Speaking score"
                      placeholder=""
                      type="number"
                      className={styles.input}
                      required
                      validatorMessage={
                        errorsLanguage?.languages
                          ? errorsLanguage?.languages[index]?.speakingScore
                              ?.message ?? ""
                          : ""
                      }
                      name={`languages.${index}.speakingScore`}
                      register={registerLanguage}
                      onKeyDown={handleEnter}
                      parentClassName={styles.inputWrap}
                      value={watchLanguage(`languages.${index}.speakingScore`)}
                    />
                  </div>
                );
              })}
              <div style={{ width: "100%" }}>
                <button
                  className={styles.addBtn}
                  onClick={handleSubmitLanguage(addLanguage)}
                >
                  <PlusIcon /> Add New
                </button>
              </div>

              <button
                onClick={handleSubmitLanguage(
                  onSubmitLanguage,
                  onSubmitLanguageError
                )}
                className={styles.nextBtn}
              >
                Next
              </button>
            </form>
          ) : stage === 4 ? (
            <>
              <form className={styles.formUploadWrap}>
                <p className={styles.formTtl}>Personal</p>
                <div className={styles.docsWrap}>
                  {personalDocs.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.uploadWrap} ${
                        watchDocuments(item.key) ? styles.uploadSuccess : ""
                      }`}
                    >
                      <p className={styles.uploadLabel}>{item.label}</p>
                      <div className={styles.uploadLabelWrap}>
                        {watchDocuments(item.key) ? (
                          <>
                            <div className={styles.icon}>
                              <DocumentSketchIcon />
                            </div>
                            <div className={styles.formFileUploadLabel}>
                              <p>{watchDocuments(item.key)?.name}</p>
                              <p>
                                {getMegaByte(watchDocuments(item.key)?.size)} MB
                              </p>
                              <div className={styles.uploadProgress}></div>
                            </div>
                            <DeleteIcon
                              role="button"
                              tabIndex={0}
                              onClick={() => setValueDocuments(item.key, null)}
                            />
                          </>
                        ) : (
                          <label
                            role="button"
                            className={styles.formFileUploadLabel}
                            htmlFor={item.key}
                          >
                            <div className={styles.icon}>
                              <DocUploadIcon />
                            </div>
                            <span className={styles.uploadTxt}>
                              Click to upload
                            </span>
                            <p className={styles.formats}>
                              JPG, PNG, PDF, DOC, DOCX ( MAX 5MB )
                            </p>

                            <input
                              className={styles.formFileUploadInput}
                              id={item.key}
                              type="file"
                              name="file"
                              onChange={(e) =>
                                handleDocUpload(e, item.fileName, item.key)
                              }
                              required
                              accept=".pdf, .png, .jpg, .jpeg, .doc, .docx"
                            />
                          </label>
                        )}
                      </div>
                      {!watchDocuments(item.key) &&
                      errorsDocuments[item.key]?.message ? (
                        <p className={styles.errorMsg}>
                          <WarningIcon /> {errorsDocuments[item.key]?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
                <p className={styles.formTtl}>Education & Certificates</p>
                <div className={styles.docsWrap}>
                  {eduCertDocs.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.uploadWrap} ${
                        watchDocuments(item.key) ? styles.uploadSuccess : ""
                      }`}
                    >
                      <p className={styles.uploadLabel}>{item.label}</p>
                      <div className={styles.uploadLabelWrap}>
                        {watchDocuments(item.key) ? (
                          <>
                            <div className={styles.icon}>
                              <DocumentSketchIcon />
                            </div>
                            <div className={styles.formFileUploadLabel}>
                              <p>{watchDocuments(item.key)?.name}</p>
                              <p>
                                {getMegaByte(watchDocuments(item.key)?.size)} MB
                              </p>
                              <div className={styles.uploadProgress}></div>
                            </div>
                            <DeleteIcon
                              role="button"
                              tabIndex={0}
                              onClick={() => setValueDocuments(item.key, null)}
                            />
                          </>
                        ) : (
                          <label
                            role="button"
                            className={styles.formFileUploadLabel}
                            htmlFor={item.key}
                          >
                            <div className={styles.icon}>
                              <DocUploadIcon />
                            </div>
                            <span className={styles.uploadTxt}>
                              Click to upload
                            </span>
                            <p className={styles.formats}>
                              JPG, PNG, PDF, DOC, DOCX ( MAX 5MB )
                            </p>

                            <input
                              className={styles.formFileUploadInput}
                              id={item.key}
                              type="file"
                              name="file"
                              onChange={(e) =>
                                handleDocUpload(e, item.fileName, item.key)
                              }
                              required
                              accept=".pdf, .png, .jpg, .jpeg, .doc, .docx"
                            />
                          </label>
                        )}
                      </div>
                      {!watchDocuments(item.key) &&
                      errorsDocuments[item.key]?.message ? (
                        <p className={styles.errorMsg}>
                          <WarningIcon /> {errorsDocuments[item.key]?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
                <p className={styles.formTtl}>Work</p>
                <div className={styles.docsWrap}>
                  {workDocs.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.uploadWrap} ${
                        watchDocuments(item.key) ? styles.uploadSuccess : ""
                      }`}
                    >
                      <p className={styles.uploadLabel}>{item.label}</p>
                      <div className={styles.uploadLabelWrap}>
                        {watchDocuments(item.key) ? (
                          <>
                            <div className={styles.icon}>
                              <DocumentSketchIcon />
                            </div>
                            <div className={styles.formFileUploadLabel}>
                              <p>{watchDocuments(item.key)?.name}</p>
                              <p>
                                {getMegaByte(watchDocuments(item.key)?.size)} MB
                              </p>
                              <div className={styles.uploadProgress}></div>
                            </div>
                            <DeleteIcon
                              role="button"
                              tabIndex={0}
                              onClick={() => setValueDocuments(item.key, null)}
                            />
                          </>
                        ) : (
                          <label
                            role="button"
                            className={styles.formFileUploadLabel}
                            htmlFor={item.key}
                          >
                            <div className={styles.icon}>
                              <DocUploadIcon />
                            </div>
                            <span className={styles.uploadTxt}>
                              Click to upload
                            </span>
                            <p className={styles.formats}>
                              JPG, PNG, PDF, DOC, DOCX ( MAX 5MB )
                            </p>

                            <input
                              className={styles.formFileUploadInput}
                              id={item.key}
                              type="file"
                              name="file"
                              onChange={(e) =>
                                handleDocUpload(e, item.fileName, item.key)
                              }
                              required
                              accept=".pdf, .png, .jpg, .jpeg, .doc, .docx"
                            />
                          </label>
                        )}
                      </div>
                      {!watchDocuments(item.key) &&
                      errorsDocuments[item.key]?.message ? (
                        <p className={styles.errorMsg}>
                          <WarningIcon /> {errorsDocuments[item.key]?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>

                <>
                  <div className={styles.refTtlWrap}>
                    <p className={styles.formTtl}>Work & Academic References</p>
                    <button
                      className={styles.addBtn}
                      onClick={() => {
                        const prev = watchDocuments("referenceLetters");
                        const newDoc: File = new File([], "");

                        if (prev.every((item) => item.size > 0))
                          setValueDocuments("referenceLetters", [
                            ...prev,
                            newDoc,
                          ]);
                      }}
                    >
                      <PlusIcon /> New Reference
                    </button>
                  </div>
                  <div className={styles.docsWrap}>
                    {watchDocuments("referenceLetters").length > 0 &&
                      watchDocuments("referenceLetters").map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.uploadWrap} ${
                            watchDocuments("referenceLetters")[index].size > 0
                              ? styles.uploadSuccess
                              : ""
                          }`}
                        >
                          <p className={styles.uploadLabel}>
                            {ref.label} ({index + 1})
                          </p>
                          <div className={styles.uploadLabelWrap}>
                            {watchDocuments("referenceLetters")[index].size >
                            0 ? (
                              <>
                                <div className={styles.icon}>
                                  <DocumentSketchIcon />
                                </div>
                                <div className={styles.formFileUploadLabel}>
                                  <p>
                                    {watchDocuments("referenceLetters")[
                                      index
                                    ].name.replace(" VobbRef", "")}
                                  </p>
                                  <p>
                                    {getMegaByte(
                                      watchDocuments("referenceLetters")[index]
                                        .size
                                    )}{" "}
                                    MB
                                  </p>
                                  <div className={styles.uploadProgress}></div>
                                </div>
                                <DeleteIcon
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => {
                                    const prevList =
                                      watchDocuments("referenceLetters");

                                    if (prevList.length > 1) {
                                      prevList.splice(index, 1);
                                      setValueDocuments("referenceLetters", [
                                        ...prevList,
                                      ]);
                                    } else {
                                      const newList = prevList.map(
                                        (item, idx) =>
                                          idx === index
                                            ? new File([], "")
                                            : item
                                      );

                                      setValueDocuments(
                                        "referenceLetters",
                                        newList
                                      );
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <label
                                role="button"
                                className={styles.formFileUploadLabel}
                                htmlFor={ref.key}
                              >
                                <div className={styles.icon}>
                                  <DocUploadIcon />
                                </div>
                                <span className={styles.uploadTxt}>
                                  Click to upload
                                </span>
                                <p className={styles.formats}>
                                  JPG, PNG, PDF, DOC, DOCX ( MAX 5MB )
                                </p>

                                <input
                                  className={styles.formFileUploadInput}
                                  id={ref.key}
                                  type="file"
                                  name="file"
                                  onChange={(e) => handleRefUpload(e, index)}
                                  accept=".pdf, .png, .jpg, .jpeg, .doc, .docx"
                                />
                              </label>
                            )}
                          </div>
                          {!watchDocuments(ref.key) &&
                          errorsDocuments[ref.key]?.message ? (
                            <p className={styles.errorMsg}>
                              <WarningIcon />{" "}
                              {errorsDocuments[ref.key]?.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                  </div>
                </>

                <>
                  <div className={styles.refTtlWrap}>
                    <p className={styles.formTtl}>Other documents</p>
                    <button
                      className={styles.addBtn}
                      onClick={() => {
                        const prev = watchDocuments("others");
                        const newDoc: File = new File([], "");

                        if (prev.every((item) => item.size > 0))
                          setValueDocuments("others", [...prev, newDoc]);
                      }}
                    >
                      <PlusIcon /> Others
                    </button>
                  </div>
                  <div className={styles.docsWrap}>
                    {watchDocuments("others").length > 0 &&
                      watchDocuments("others").map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.uploadWrap} ${
                            watchDocuments("others")[index].size > 0
                              ? styles.uploadSuccess
                              : ""
                          }`}
                        >
                          <p className={styles.uploadLabel}>
                            {other.label} ({index + 1})
                          </p>
                          <div className={styles.uploadLabelWrap}>
                            {watchDocuments("others")[index].size > 0 ? (
                              <>
                                <div className={styles.icon}>
                                  <DocumentSketchIcon />
                                </div>
                                <div className={styles.formFileUploadLabel}>
                                  <p>
                                    {watchDocuments("others")[
                                      index
                                    ].name.replace(" VobbOther", "")}
                                  </p>
                                  <p>
                                    {getMegaByte(
                                      watchDocuments("others")[index].size
                                    )}{" "}
                                    MB
                                  </p>
                                  <div className={styles.uploadProgress}></div>
                                </div>
                                <DeleteIcon
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => {
                                    const prevList = watchDocuments("others");

                                    if (prevList.length > 1) {
                                      prevList.splice(index, 1);
                                      setValueDocuments("others", [
                                        ...prevList,
                                      ]);
                                    } else {
                                      const newList = prevList.map(
                                        (item, idx) =>
                                          idx === index
                                            ? new File([], "")
                                            : item
                                      );

                                      setValueDocuments("others", newList);
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <label
                                role="button"
                                className={styles.formFileUploadLabel}
                                htmlFor={other.key}
                              >
                                <div className={styles.icon}>
                                  <DocUploadIcon />
                                </div>
                                <span className={styles.uploadTxt}>
                                  Click to upload
                                </span>
                                <p className={styles.formats}>
                                  JPG, PNG, PDF, DOC, DOCX ( MAX 5MB )
                                </p>

                                <input
                                  className={styles.formFileUploadInput}
                                  id={other.key}
                                  type="file"
                                  name="file"
                                  onChange={(e) => handleOtherUpload(e, index)}
                                  accept=".pdf, .png, .jpg, .jpeg, .doc, .docx"
                                />
                              </label>
                            )}
                          </div>
                          {!watchDocuments(other.key) &&
                          errorsDocuments[other.key]?.message ? (
                            <p className={styles.errorMsg}>
                              <WarningIcon />{" "}
                              {errorsDocuments[other.key]?.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                  </div>
                </>
              </form>
              <button
                onClick={handleSubmitDocuments(onSubmitDocument)}
                className={styles.nextBtn}
              >
                Register
              </button>
            </>
          ) : (
            ""
          )}
        </div>
        <small className={styles.poweredBy}>
          Powered by{" "}
          <a href="https://vobb.io/atlas" target={"blank"}>
            <VobbLogoFull className={styles.logo} />
          </a>
        </small>
      </main>
    </>
  );
};

export default RegisterAsProspect;
