import { MinusIcon, PlusIcon } from "assets";
import * as React from "react";
import styles from "./styles.module.css";

export interface AccordionInfo {
  question: string;
  answer: string;
}

export interface AccordionItemProps {
  active: number;
  state: number;
  changeActive: (state: number) => void;
  question: string;
  answer: string;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  active,
  state,
  changeActive,
  question,
  answer,
}) => {
  const contentEl = React.useRef<HTMLDivElement>(null);
  return (
    <div
      className={`${styles.faqItem} ${
        active === state ? styles.activeItem : styles.inactiveItem
      }`}
    >
      <button
        onClick={() => changeActive(active === state ? -1 : state)}
        className={styles.faqBtn}
      >
        <span>{question}</span>{" "}
        {state === active ? <MinusIcon /> : <PlusIcon />}
      </button>
      <div
        ref={contentEl}
        className={styles.faqBody}
        style={
          active === state && contentEl.current
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div>{answer}</div>
      </div>
    </div>
  );
};
