import { StudentNavTickIcon, VobbLogoFull } from "assets";
import { NavItemProp } from "hooks";
import { useState } from "react";
import styles from "./styles.module.css";

// Interface Props
interface IProps {
  navItems: NavItemProp[];
  isActive: (tabName: string) => boolean;
  updateCurrentNavItem: (tabItem: NavItemProp) => void;
}

const StudentAppNav: React.FC<IProps> = ({
  navItems,
  updateCurrentNavItem,
  isActive,
}) => {
  return (
    <>
      <div className={styles.nav}>
        <VobbLogoFull className={styles.logo1} />
        <ul className={`${styles.linksContainer}`}>
          {navItems.map((item, idx) => {
            // Check if tab is active
            const isTabActive = isActive(item.title);
            const isActiveStyles = isTabActive ? styles.active : "";

            // Tab completion styles
            const completedStyles = item.isCompleted ? styles.completed : "";
            const incompleteTabStyles =
              !item.isCompleted && !isTabActive ? styles.incomplete : "";

            return (
              <li
                key={item.title}
                className={`${styles.linkItem} ${isActiveStyles} ${completedStyles} ${incompleteTabStyles} `}
                onClick={() =>
                  !item.isCompleted ? () => {} : updateCurrentNavItem(item)
                }
              >
                <span className={styles.count}>
                  {item.isCompleted ? <StudentNavTickIcon /> : idx + 1}
                </span>
                <span className={styles.linkTitle}>{item.title}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export { StudentAppNav };
