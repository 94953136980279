import { RefObject, useEffect } from "react";

type AnyEvent = MouseEvent | TouchEvent;

export const useOutsideAlerter = (
  ref: RefObject<HTMLElement>,
  closeFunction: (event: AnyEvent) => void
): void => {
  useEffect(() => {
    /**
     * Hide if clicked on outside of element
     */
    const handleClickOutside = (event: AnyEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeFunction && closeFunction(event);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener(`touchstart`, handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.addEventListener(`touchstart`, handleClickOutside);
    };
  }, [ref]);
};
