import { growthClaimsGif } from "assets";
import { LandingBuildScaleIcon, LandingHeadStartIcon } from "assets/vectors";
import { LandingInfoBox } from "routes/Home/components";
import { Routes } from "utils/routes";
import homePageStyles from "./homePage.module.css";

const GrowthClaims = () => {
  return (
    <section
      className={`${homePageStyles.homeSection} ${homePageStyles.growthClaimSection} landingWrapper`}
    >
      <div className={homePageStyles.growthClaimContent}>
        <img src={growthClaimsGif} alt="growthclaims" />
      </div>
      <div className={homePageStyles.growthClaimInfoBoxes}>
        <h2>Growth at any Level</h2>
        <div>
          <LandingInfoBox
            title="Built for Head Start"
            Icon={LandingHeadStartIcon}
            description="Don't have a business yet? Start your education travel business with no hassle."
            boxLink={Routes.access}
            styles={{
              marginRight: "50px",
              paddingLeft: "0px",
              marginBottom: "32px",
            }}
          />
          <LandingInfoBox
            title="Built for Scale"
            Icon={LandingBuildScaleIcon}
            description="Gain direct access to global universities and increase your offerings."
            boxLink={Routes.access}
          />
        </div>
      </div>
    </section>
  );
};

export default GrowthClaims;
