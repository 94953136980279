import {
  CaretLeftDarkSVGIcon,
  CaretLeftLightSVGIcon,
  CaretRightDarkSVGIcon,
  CaretRightLightSVGIcon,
} from "assets";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Select from "react-select";
import styles from "./pagination.module.css";

interface PageProps {
  pages: number;
  handlePage: (page: number) => void;
  total?: number;
  currentPage?: number;
  reset?: boolean;
  itemsToDisplay?: number[];
  handleDisplayLength?: (num: number) => void;
  defaultLength?: number;
  className?: string;
  hide?: boolean;
}

const Pagination: React.FC<PageProps> = (props) => {
  const location = useLocation();
  const [middlePages, setMiddle] = useState<number[]>([]);
  const [activePage, setActive] = useState(1);

  function calcMiddle(newPage: number) {
    let middle: number[] = [];
    if (props.pages - 1 <= 5) {
      for (let i = 2; i < props.pages; i++) {
        middle.push(i);
      }
    } else if (newPage === 1) {
      middle = [2, -1];
    } else if (newPage === props.pages) {
      middle = [-1, newPage - 1];
    } else if (newPage === 2) {
      middle = [2, 3, -1];
    } else if (newPage === 3) {
      middle = [2, 3, 4, -1];
    } else if (newPage + 1 === props.pages) {
      middle = [-1, newPage - 1, newPage];
    } else {
      middle = [-1, newPage - 1, newPage, newPage + 1];
      if (newPage + 1 < props.pages - 1) middle.push(-1);
    }

    setActive(newPage);
    setMiddle(middle);
  }

  function updateSearch(newPage: number) {
    calcMiddle(newPage);
    props.handlePage(newPage);
  }

  function handleControl(curr: number, forward: boolean) {
    if (forward && curr < props.pages) updateSearch(curr + 1);
    else if (!forward && curr > 1) updateSearch(curr - 1);
  }

  useEffect(() => {
    calcMiddle(1);
  }, [location.pathname, props.pages]);

  useEffect(() => {
    props.currentPage && setActive(props.currentPage);
  }, []);

  useEffect(() => {
    setActive(props.currentPage ?? 1);
  }, [props.reset]);

  if (props.hide) {
    return null;
  }
  return (
    <div className={`${styles.v_saved_list_ctrl_sec} ${props.className} ${props.pages > 1 ? "" : styles.rightAlign}`}>
      {props.pages > 1 ? (
        <div className={styles.v_saved_list_ctrl}>
          <span
            className={`${styles.v_saved_list_ctrl_box} ${
              activePage === 1 && styles.v_saved_list_ctrl_end
            }`}
            onClick={() => handleControl(activePage, false)}
          >
            {activePage === 1 ? (
              <CaretLeftLightSVGIcon />
            ) : (
              <CaretLeftDarkSVGIcon />
            )}
          </span>
          <span
            className={`${styles.v_saved_list_ctrl_box} ${
              activePage === 1 && styles.v_saved_list_ctrl_active
            }`}
            onClick={() => updateSearch(1)}
          >
            1
          </span>
          {middlePages.length > 0 &&
            middlePages.map((item, index) => (
              <span
                key={index}
                className={`${styles.v_saved_list_ctrl_box} ${
                  activePage === item && styles.v_saved_list_ctrl_active
                }`}
                onClick={() => item !== -1 && updateSearch(item)}
              >
                {item === -1 ? "..." : item}
              </span>
            ))}
          <span
            className={`${styles.v_saved_list_ctrl_box} ${
              activePage === props.pages && styles.v_saved_list_ctrl_active
            }`}
            onClick={() => updateSearch(props.pages)}
          >
            {props.pages}
          </span>
          <span
            className={`${styles.v_saved_list_ctrl_box} ${
              activePage === props.pages && styles.v_saved_list_ctrl_end
            }`}
            onClick={() => handleControl(activePage, true)}
          >
            {activePage === props.pages ? (
              <CaretRightLightSVGIcon title="caret" />
            ) : (
              <CaretRightDarkSVGIcon title="caret" />
            )}
          </span>
        </div>
      ) : (
        ""
      )}

      {props.itemsToDisplay &&
      props.defaultLength &&
      props.handleDisplayLength &&
      props.itemsToDisplay?.length > 0 ? (
        <div className={styles.display}>
          <span>Display per page:</span>
          <Select
            placeholder="Country"
            options={props.itemsToDisplay.map((item) => ({
              label: item,
              value: item,
            }))}
            onChange={(val: any) => {
              props.handleDisplayLength && props.handleDisplayLength(val.value);
            }}
            value={
              props.defaultLength
                ? { label: props.defaultLength, value: props.defaultLength }
                : null
            }
            className={`${styles.paginationDisplay}`}
            classNamePrefix="paginationSelect"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Pagination;
