// Action Mapper
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

/**
 * =============================================
 * // Update types for all student records
 * =============================================
 */

export enum StudentDetailsUpdateTypes {
  UpdateStudentBio = "UpdateStudentBio",
  UpdateDocuments = "UpdateDocuments",
  UpdateSchoolInfo = "UpdateSchoolInfo",
  UpdatePageCompletion = "UpdatePageCompletion",
  UpdateActiveTab = "UpdateActiveTab",
}

/**
 * =============================================
 * // Student Bio Update Types and Actions
 * =============================================
 */

export type StudentBioDataProps = {
  firstname: string;
  lastname: string;
  gender: string;
  dateOfBirth: string;
  studentEmail: string;
  mobileNumber: string;
  nationality: string;
  passportNumber: string;
  mothersMedian: string;
  fathersName: string;
  address: string;
};

type StudentAppDetailsPayload = {
  [StudentDetailsUpdateTypes.UpdateStudentBio]: StudentBioDataProps;
};

export type StudentBioUpdateActions =
  ActionMap<StudentAppDetailsPayload>[keyof ActionMap<StudentAppDetailsPayload>];

/**
 * =============================================
 * // University Update Types and Actions
 * =============================================
 */

export type UniversityDataProps = {
  university: { value: string; label: string };
  universityCountry: { value: string; label: string };
  degree: string;
  courseOfStudy: string;
  additionalNote: string;
  id?: string;
};

export type StudentSchoolDetailsProps = UniversityDataProps[];

type StudentSchoolDetailsPayload = {
  [StudentDetailsUpdateTypes.UpdateSchoolInfo]: StudentSchoolDetailsProps;
};

export type StudentSchoolDetailsActions =
  ActionMap<StudentSchoolDetailsPayload>[keyof ActionMap<StudentSchoolDetailsPayload>];

/**
 * =============================================
 * // Application Files type and Actions
 * =============================================
 */

export type StudentAppFilesTypes = any[];

type StudentAppFilesPayload = {
  [StudentDetailsUpdateTypes.UpdateDocuments]: StudentAppFilesTypes;
};

export type StudentAppFilesActions =
  ActionMap<StudentAppFilesPayload>[keyof ActionMap<StudentAppFilesPayload>];

/**
 * =============================================
 * // Page Completion Props
 * =============================================
 */

export type StudentNavTypes = "student" | "school" | "documents";
export type PageCompletionType = {
  student: boolean;
  school: boolean;
  documents: boolean;
  activeApplicationTab: StudentNavTypes;
};

type PageCompletionPayload = {
  [StudentDetailsUpdateTypes.UpdatePageCompletion]: PageCompletionType;
};

export type PageCompletionAction =
  ActionMap<PageCompletionPayload>[keyof ActionMap<PageCompletionPayload>];

/**
 * =============================================
 * // All Student records props
 * =============================================
 */
export type InitialStudentFormStateType = {
  studentBioInfo: StudentBioDataProps;
  studentSchoolDetails: StudentSchoolDetailsProps;
  uploadedApplicationFiles: StudentAppFilesTypes;
  pageCompletionChecks: PageCompletionType;
};

export type AllActionTypes =
  | StudentAppFilesActions
  | StudentSchoolDetailsActions
  | StudentBioUpdateActions
  | PageCompletionAction;
