import React from "react";
import textInputStyles from "../Input/styles.module.css";
import styles from "./styles.module.css";
import Select from "react-select";

export interface optionState {
  value: string;
  label: string;
}

const initialOptionState: optionState = {
  value: "",
  label: "",
};

export interface SelectProps {
  dropdownItems: optionState[];
  onDropItemSelect: (val: optionState) => void;
  placeholder: string;
  inputLabel: string;
  selectedItem: optionState;
  containerStyles?: React.CSSProperties;
  customContainerClassName?: string;
  selectGroupID: string;
}

const SelectBox: React.FC<SelectProps> = ({
  selectGroupID = "",
  dropdownItems = [initialOptionState],
  onDropItemSelect,
  placeholder = "",
  containerStyles,
  customContainerClassName = "",
  selectedItem,
  inputLabel,
}) => {
  return (
    <div
      className={`${styles.selectbox_wrapper} ${customContainerClassName}`}
      style={containerStyles}
    >
      {inputLabel && (
        <label
          htmlFor={selectGroupID}
          className={`${textInputStyles.textInputLabel}`}
        >
          {inputLabel}
        </label>
      )}

      <Select
        isSearchable={true}
        value={selectedItem?.value ? selectedItem : null}
        onChange={(val) => val && onDropItemSelect(val)}
        options={dropdownItems}
        placeholder={placeholder}
        classNamePrefix="formSelect"
        className={styles.formInputSelect}
        required
      />
    </div>
  );
};

export { SelectBox };
