import { RejectSVGIcon } from "assets";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../utils/routes";
import styles from "./styles.module.css";

interface PrivacyProps {
  closeModal: () => void;
  show: boolean;
}

const Privacy: React.FC<PrivacyProps> = ({ closeModal, show }) => {
  return (
    <>
      <Modal
        className={styles["v-terms"]}
        show={show}
        onHide={closeModal}
        centered
      >
        <RejectSVGIcon
          className={styles.svg}
          onClick={closeModal}
          title="close"
          role="button"
        />

        <Modal.Title>PRIVACY POLICY</Modal.Title>
        <Modal.Body>
          <p>
            This privacy policy applies between you, the User of this Website
            and Vobb, ltd, the owner and provider of this Website. Vobb, ltd
            takes the privacy of your information very seriously. This privacy
            policy applies to our use of any and all Data collected by us or
            provided by you in relation to your use of the Website. This privacy
            policy should be read alongside, and in addition to, our Terms and
            Conditions, which can be found at:
            <Link to={Routes.home}>terms-and-condition</Link>.
            <br />
            <strong>Please read this privacy policy carefully.</strong>
          </p>
          <h6>Definitions and interpretation</h6>
          <ol>
            <li>
              In this privacy policy, the following definitions are used:
              <div className={styles["v-terms-grid"]}>
                <div>Data</div>
                <div>
                  collectively all information that you submit to Vobb, ltd via
                  the Website. This definition incorporates, where applicable,
                  the definitions provided in the Data Protection Laws;
                </div>
                <div>Data Protection Laws</div>
                <div>
                  any applicable law relating to the processing of personal
                  Data, including but not limited to the GDPR, and any national
                  implementing and supplementary laws, regulations and secondary
                  legislation;
                </div>
                <div>GDPR</div>
                <div>the UK General Data Protection Regulation;</div>
                <div>Vobb, ltd, we or us</div>
                <div>
                  Vobb, ltd, a company incorporated in England and Wales with
                  registered number 13305815 whose registered office is at 71-75
                  Shelton Street Covent Garden, London, WC2H 9JQ;
                </div>
                <div>User or you</div>
                <div>
                  any third party that accesses the Website and is not either
                  (i) employed by Vobb, ltd and acting in the course of their
                  employment or (ii) engaged as a consultant or otherwise
                  providing services to Vobb, ltd and accessing the Website in
                  connection with the provision of such services; and
                </div>
                <div>Website</div>
                <div>
                  the website that you are currently using, www.vobb.io, and any
                  sub-domains of this site unless expressly excluded by their
                  own terms and conditions.
                </div>
              </div>
            </li>
            <li>
              In this privacy policy, unless the context requires a different
              interpretation:
              <ol className={styles["v-terms-alpha"]}>
                <li>the singular includes the plural and vice versa;</li>
                <li>
                  references to sub-clauses, clauses, schedules or appendices
                  are to sub-clauses, clauses, schedules or appendices of this
                  privacy policy;
                </li>
                <li>
                  a reference to a person includes firms, companies, government
                  entities, trusts and partnerships;
                </li>
                <li>
                  "including" is understood to mean "including without
                  limitation";
                </li>
                <li>
                  reference to any statutory provision includes any modification
                  or amendment of it;
                </li>
                <li>
                  the headings and sub-headings do not form part of this privacy
                  policy.
                </li>
              </ol>
            </li>
            <h6>Scope of this privacy policy</h6>
            <li>
              This privacy policy applies only to the actions of Vobb, ltd and
              Users with respect to this Website. It does not extend to any
              websites that can be accessed from this Website including, but not
              limited to, any links we may provide to social media websites.
            </li>
            <li>
              For purposes of the applicable Data Protection Laws, Vobb, ltd is
              the "data controller". This means that Vobb, ltd determines the
              purposes for which, and the manner in which, your Data is
              processed.
            </li>
            <h6>Data collected</h6>

            <li>
              We may collect the following Data, which includes personal Data,
              from you:
              <ol className={styles["v-terms-alpha"]}>
                <li>name;</li>
                <li>date of birth;</li>
                <li>gender;</li>
                <li>job title;</li>
                <li>profession;</li>
                <li>
                  contact Information such as email addresses and telephone
                  numbers
                </li>
                <li>
                  demographic information such as postcode, preferences and
                  interests;
                </li>
                <li>
                  financial information such as credit / debit card numbers;
                </li>
                <li>IP address (automatically collected);</li>
                <li>web browser type and version (automatically collected);</li>
                <li>operating system (automatically collected);</li>
                <li>
                  a list of URLs starting with a referring site, your activity
                  on this Website, and the site you exit to (automatically
                  collected);
                </li>
                in each case, in accordance with this privacy policy.
              </ol>
            </li>
            <h6>How we collect Data</h6>
            <li>
              We collect Data in the following ways:
              <ol className={styles["v-terms-alpha"]}>
                <li>data is given to us by you ; and</li>
                <li>data is collected automatically.</li>
              </ol>
            </li>
            <h6>Data that is given to us by you</h6>
            <li>
              Vobb, ltd will collect your Data in a number of ways, for example:
              <ol className={styles["v-terms-alpha"]}>
                <li>
                  when you contact us through the Website, by telephone, post,
                  e-mail or through any other means;
                </li>
                <li>
                  when you register with us and set up an account to receive our
                  products/services;
                </li>
                <li>
                  when you complete surveys that we use for research purposes
                  (although you are not obliged to respond to them);
                </li>
                <li>
                  when you make payments to us, through this Website or
                  otherwise;
                </li>
                <li>
                  when you elect to receive marketing communications from us;
                </li>
                <li>when you use our services;</li>
              </ol>
              in each case, in accordance with this privacy policy.
            </li>
            <h6>Data that is collected automatically</h6>
            <li>
              To the extent that you access the Website, we will collect your
              Data automatically, for example:
              <ol className={styles["v-terms-alpha"]}>
                <li>
                  we automatically collect some information about your visit to
                  the Website. This information helps us to make improvements to
                  Website content and navigation, and includes your IP address,
                  the date, times and frequency with which you access the
                  Website and the way you use and interact with its content.
                </li>
              </ol>
            </li>
            <h6>Our use of Data</h6>
            <li>
              Any or all of the above Data may be required by us from time to
              time in order to provide you with the best possible service and
              experience when using our Website. Specifically, Data may be used
              by us for the following reasons:
              <ol>
                <li>internal record keeping;</li>
                <li>improvement of our products / services;</li>
                <li>
                  transmission by email of marketing materials that may be of
                  interest to you;
                </li>
                <li>
                  contact for market research purposes which may be done using
                  email, telephone, fax or mail. Such information may be used to
                  customise or update the Website;
                </li>
              </ol>
              in each case, in accordance with this privacy policy.
            </li>
            <li>
              We may use your Data for the above purposes if we deem it
              necessary to do so for our legitimate interests. If you are not
              satisfied with this, you have the right to object in certain
              circumstances (see the section headed "Your rights" below).
            </li>
            <li>
              For the delivery of direct marketing to you via e-mail, we'll need
              your consent, whether via an opt-in or soft-opt-in:
              <ol className={styles["v-terms-alpha"]}>
                <li>
                  Soft opt-in consent is a specific type of consent which
                  applies when you have previously engaged with us (for example,
                  you contact us to ask us for more details about a particular
                  product/service, and we are marketing similar
                  products/services). Under "soft opt-in" consent, we will take
                  your consent as given unless you opt-out.
                </li>
                <li>
                  for other types of e-marketing, we are required to obtain your
                  explicit consent; that is, you need to take positive and
                  affirmative action when consenting by, for example, checking a
                  tick box that we'll provide.
                </li>
                <li>
                  If you are not satisfied about our approach to marketing, you
                  have the right to withdraw consent at any time. To find out
                  how to withdraw your consent, see the section headed "Your
                  rights" below.
                </li>
              </ol>
            </li>
            <li>
              When you register with us and set up an account to receive our
              services, the legal basis for this processing is the performance
              of a contract between you and us and/or taking steps, at your
              request, to enter into such a contract.
            </li>
            <h6>Who we share Data with</h6>
            <li>
              We may share your Data with the following groups of people for the
              following reasons:
              <ol className={styles["v-terms-alpha"]}>
                <li>
                  any of our group companies or affiliates - to ensure the
                  proper administration of your website and business;
                </li>
                <li>
                  our employees, agents and/or professional advisors - to obtain
                  advice from professional advisers;
                </li>
                <li>
                  third party service providers who provide services to us which
                  require the processing of personal data - to help third party
                  service providers in receipt of any shared data to perform
                  functions on our behalf to help ensure the website runs
                  smoothly
                </li>
                <li>
                  third party payment providers who process payments made over
                  the Website - to enable third party payment providers to
                  process user payments and refunds;
                </li>
                <li>
                  relevant authorities - to facilitate the detection of crime or
                  the collection of taxes or duties;
                </li>
              </ol>
              in each case, in accordance with this privacy policy.
            </li>
            <h6>Keeping Data secure</h6>
            <li>
              We will use technical and organisational measures to safeguard
              your Data, for example:
              <ol className={styles["v-terms-alpha"]}>
                <li>
                  access to your account is controlled by a password and a
                  username that is unique to you.
                </li>
                <li>we store your Data on secure servers.</li>
                <li>
                  payment details are encrypted using SSL technology (typically
                  you will see a lock icon or green address bar (or both) in
                  your browser when we use this technology.
                </li>
              </ol>
            </li>
            <li>
              Technical and organisational measures include measures to deal
              with any suspected data breach. If you suspect any misuse or loss
              or unauthorised access to your Data, please let us know
              immediately by contacting us via this e-mail address:
              admin@vobb.io.
            </li>
            <li>
              If you want detailed information from Get Safe Online on how to
              protect your information and your computers and devices against
              fraud, identity theft, viruses and many other online problems,
              please visit www.getsafeonline.org. Get Safe Online is supported
              by HM Government and leading businesses.
            </li>
            <h6>Data retention</h6>
            <li>
              Unless a longer retention period is required or permitted by law,
              we will only hold your Data on our systems for the period
              necessary to fulfil the purposes outlined in this privacy policy
              or until you request that the Data be deleted.
            </li>
            <li>
              Even if we delete your Data, it may persist on backup or archival
              media for legal, tax or regulatory purposes.
            </li>
            <h6>Your rights</h6>
            <li>
              You have the following rights in relation to your Data:
              <ol className={styles["v-terms-alpha"]}>
                <li>
                  <strong>Right to access</strong> - the right to request (i)
                  copies of the information we hold about you at any time, or
                  (ii) that we modify, update or delete such information. If we
                  provide you with access to the information we hold about you,
                  we will not charge you for this, unless your request is
                  "manifestly unfounded or excessive." Where we are legally
                  permitted to do so, we may refuse your request. If we refuse
                  your request, we will tell you the reasons why.
                </li>
                <li>
                  <strong>Right to correct</strong> - the right to have your
                  Data rectified if it is inaccurate or incomplete.
                </li>
                <li>
                  <strong>Right to erase</strong> - the right to request that we
                  delete or remove your Data from our systems.
                </li>
                <li>
                  <strong>Right to restrict our use of your Data</strong> - the
                  right to "block" us from using your Data or limit the way in
                  which we can use it.
                </li>
                <li>
                  <strong>Right to data portability</strong> - the right to
                  request that we move, copy or transfer your Data.
                </li>
                <li>
                  <strong>Right to object</strong> - the right to object to our
                  use of your Data including where we use it for our legitimate
                  interests.
                </li>
              </ol>
            </li>
            <li>
              To make enquiries, exercise any of your rights set out above, or
              withdraw your consent to the processing of your Data (where
              consent is our legal basis for processing your Data), please
              contact us via this e-mail address: support@vobb.io.
            </li>
            <li>
              If you are not satisfied with the way a complaint you make in
              relation to your Data is handled by us, you may be able to refer
              your complaint to the relevant data protection authority. For the
              UK, this is the Information Commissioner's Office (ICO). The ICO's
              contact details can be found on their website at
              <a target="blank" href="https://ico.org.uk/">
                https://ico.org.uk/
              </a>
              .
            </li>
            <li>
              It is important that the Data we hold about you is accurate and
              current. Please keep us informed if your Data changes during the
              period for which we hold it.
            </li>
            <h6>
              Transfers outside the United Kingdom and European Economic Area
            </h6>
            <li>
              Data which we collect from you may be stored and processed in and
              transferred to countries outside of the UK and European Economic
              Area (EEA). For example, this could occur if our servers are
              located in a country outside the UK or EEA or one of our service
              providers is situated in a country outside the UK or EEA. We also
              share information with our group companies, some of which are
              located outside the UK or EEA.
            </li>
            <li>
              We will only transfer Data outside the UK or EEA where it is
              compliant with data protection legislation and the means of
              transfer provides adequate safeguards in relation to your data, eg
              by way of data transfer agreement, incorporating the current
              standard contractual clauses adopted by the European Commission.
            </li>
            <li>
              To ensure that your Data receives an adequate level of protection,
              we have put in place appropriate safeguards and procedures with
              the third parties we share your Data with. This ensures your Data
              is treated by those third parties in a way that is consistent with
              the Data Protection Laws.
            </li>

            <h6>Links to other websites</h6>
            <li>
              This Website may, from time to time, provide links to other
              websites. We have no control over such websites and are not
              responsible for the content of these websites. This privacy policy
              does not extend to your use of such websites. You are advised to
              read the privacy policy or statement of other websites prior to
              using them.
            </li>
            <h6>Changes of business ownership and control</h6>
            <li>
              Vobb, ltd may, from time to time, expand or reduce our business
              and this may involve the sale and/or the transfer of control of
              all or part of Vobb, ltd. Data provided by Users will, where it is
              relevant to any part of our business so transferred, be
              transferred along with that part and the new owner or newly
              controlling party will, under the terms of this privacy policy, be
              permitted to use the Data for the purposes for which it was
              originally supplied to us.
            </li>
            <li>
              We may also disclose Data to a prospective purchaser of our
              business or any part of it.
            </li>
            <li>
              In the above instances, we will take steps with the aim of
              ensuring your privacy is protected.
            </li>
            <h6>General</h6>
            <li>
              You may not transfer any of your rights under this privacy policy
              to any other person. We may transfer our rights under this privacy
              policy where we reasonably believe your rights will not be
              affected.
            </li>
            <li>
              If any court or competent authority finds that any provision of
              this privacy policy (or part of any provision) is invalid, illegal
              or unenforceable, that provision or part-provision will, to the
              extent required, be deemed to be deleted, and the validity and
              enforceability of the other provisions of this privacy policy will
              not be affected.
            </li>
            <li>
              Unless otherwise agreed, no delay, act or omission by a party in
              exercising any right or remedy will be deemed a waiver of that, or
              any other, right or remedy.
            </li>
            <li>
              This Agreement will be governed by and interpreted according to
              the law of England and Wales. All disputes arising under the
              Agreement will be subject to the exclusive jurisdiction of the
              English and Welsh courts.
            </li>
            <h6>Changes to this privacy policy</h6>
            <li>
              Vobb, ltd reserves the right to change this privacy policy as we
              may deem necessary from time to time or as may be required by law.
              Any changes will be immediately posted on the Website and you are
              deemed to have accepted the terms of the privacy policy on your
              first use of the Website following the alterations. You may
              contact Vobb, ltd by email at support@vobb.io.
            </li>
            <h6>Attribution</h6>
            <li>
              This privacy policy was created using a document from
              <a target="blank" href="https://www.rocketlawyer.com/gb/en">
                Rocket Lawyer
              </a>
              . <br /> <strong>01 April 2021 </strong>
            </li>
          </ol>
          <button onClick={closeModal}>I have read and accepted</button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Privacy;
