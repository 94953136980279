import { ReadingDoodle, SittingDoodle, UnboxingDoodle } from "assets";
import * as React from "react";
import { Modal } from "react-bootstrap";
import styles from "./styles.module.css";

interface NoticeProps {
  show: boolean;
  closeModal: () => void;
}

const NoticeModal: React.FC<NoticeProps> = ({ show, closeModal }) => {
  return (
    <Modal className={styles.notice} show={show}>
      <Modal.Title>Studying Abroad: How It Works</Modal.Title>
      <Modal.Body>
        <p className={styles.txt}>
          Complete an application to study abroad by following these steps:
        </p>
        <div className={styles.steps}>
          <p>
            <ReadingDoodle />
            <span>Fill the application form</span>
            Complete the application form below to get started. You will receive
            an email with login credentials and a link to your personal
            dashboard.
          </p>
          <p>
            <SittingDoodle />
            <span>Get counselling</span>
            Login to your dashboard to view your application, and interact with
            a counsellor that will guide you through the application process.
          </p>
          <p>
            <UnboxingDoodle />
            <span>Provide all requirements</span>
            Upload all required documents as requested by the counsellor.
          </p>
        </div>
        <button onClick={closeModal} className={`appBtnFill ${styles.btn}`}>
          I understand
        </button>
      </Modal.Body>
    </Modal>
  );
};

export { NoticeModal };
