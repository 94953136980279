import React from "react";
import { Link } from "react-router-dom";
import cardStyles from "./landingCards.module.css";

// Landing Card Props
export interface LandingCardProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  showLink?: boolean;
  link?: string;
  target?: string
}

const LandingPageCard: React.FC<LandingCardProps> = ({
  title,
  description,
  Icon,
  showLink,
  link = "", target
}) => {
  return (
    <div className={cardStyles.cardContainer}>
      <Icon className={cardStyles.cardIcon} />
      <h3>{title}</h3>
      <p>{description}</p>

      {showLink && (
        <Link className={cardStyles.cardLink} to={link} target={target}>
          Learn More
        </Link>
      )}
    </div>
  );
};

export { LandingPageCard };
