import {
  britishCouncilImagePNG,
  ceaImagePNG,
  collabImagePNG,
  DashboardUISVGV2,
  FlexibleCommisionsSVGV2,
  IcefImagePNG,
  landingAffiliationsLogos,
  nafsaImagePNG,
} from "assets";
import Toast from "components/Toast/toast";
import { useGAEventTracker } from "hooks";
import React, { useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Routes } from "utils/routes";
import { Button } from "../../components";
import { FeedbackProps } from "../../components/FeedbackCard/index";
import FeedbackSection from "../../components/FeedbackGroup";
import LandingPageLayout from "../../layout/index";
import GrowthClaims from "./GrowthClaims";
import HeroSection from "./HeroSection";
import styles from "./homePage.module.css";
import PriceSection, { PriceSectionProps } from "./PriceSection";
import ProductivityClaims from "./ProductivityClaimSection";

const LandingHome: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const GAEventsTracker = useGAEventTracker("Request Access");

  const handleRequestAccess = () => {
    GAEventsTracker("Request access from: ", pathname);
    push(Routes.access);
  };

  // Pricing info for the landing page
  const PriceCardSectionDetails = useMemo<PriceSectionProps>(
    () => ({
      subTitle: "Yes it is 100% free forever",
      title: "Pricing",
      priceCardsDetails: [
        { title: "Business", price: 0 },
        { title: "Starter", price: 0 },
      ],
    }),
    []
  );

  // Customer Feedback
  const CustomerFeedbacks = useMemo<FeedbackProps[]>(
    () => [
      {
        feedback: `My agency runs entirely on partnerships with other agents,
                    keeping track of applications to partners have never been easier.`,
        customerName: `Godwin Nwachukwu`,
        affiliation: `Director, Mckenwin international`,
      },
      {
        feedback: `We pride ourselves with being thorough 
                    in our dealings and we are glad Vobb makes sure to hold us to those standards.`,
        customerName: `Majid Khan`,
        affiliation: `CEO, Educonnect`,
      },
      {
        feedback: `Vobb makes it easy to organize, we no longer mix up applications to partners in our inbox. 
                    It has been essential to the growth of our agency.`,
        customerName: `Ferdinard Luscious`,
      },
    ],
    []
  );

  const location = useLocation<{ isProtected: boolean }>();
  const [toast, setToast] = React.useState(false);

  React.useEffect(() => {
    if (location.state?.isProtected) {
      openToast();
    }
  }, [location.state?.isProtected]);

  const openToast = () => {
    setToast(true);
    return setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  return (
    <>
      <LandingPageLayout>
        <div className={styles.landingPageBG}>
          <HeroSection />
          <div className={`${styles.affiliatesBackgroundSection}`}>
            <section className={`${styles.affiliationsContainer}`}>
              <div className={styles.affiliationsDesc}>
                <h1>
                  Gain unrestricted access to 700+ universities across the globe
                </h1>
                <p>
                  Find the ideal university for your student. As an agent on
                  Vobb you can easily send and process applications to top
                  universities in Australia, Canada, UK and much more.
                </p>
              </div>
              <div className={styles.affiliationsLogo}>
                <img src={landingAffiliationsLogos} alt="affiliations" />
              </div>
            </section>
          </div>
          <section className={styles.marketAnalysisContainer}>
            <h3>We are happy to work with market movers</h3>
            {/* <p>
              Cumulative stat from activities of Agents that are handling
              applications on Vobb
            </p> */}

            <div className={styles.marketAnalysisCounts}>
              <div className={styles.marketAnalysisCountGroup}>
                <p>Commissions paid out</p>
                <span>$12m+</span>
              </div>
              <div className={styles.marketAnalysisCountGroup}>
                <p>Admissions</p>
                <span>9000</span>
              </div>
              <div className={styles.marketAnalysisCountGroup}>
                <p>Partner Work Force</p>
                <span>2500+</span>
              </div>
            </div>
          </section>
          {/* <section className={styles.satisfiedCustomers}>
            <h3>Enjoyed By</h3>

            <ul className={styles.satisfiedCustomersNames}>
              <li>Themiz Travels</li>
              <li>Take-Off</li>
              <li>KHC</li>
              <li>Leverage Edu</li>
              <li>Mckenwin Intl</li>
            </ul>
          </section> */}
          <section className={styles.collaborationSection}>
            <h2>Great collaboration starts here</h2>
            <p>
              Build and Scale your education consultancy career by Leveraging
              our global access to prestigious and sort after Universities and
              programs
            </p>

            <Button
              label="Get Started"
              buttonSize="medium"
              onClick={handleRequestAccess}
              linkType
              colorVariant="blue"
            />

            <img src={collabImagePNG} alt="collaboration" />
          </section>
          <section
            className={`${styles.homeSection} ${styles.bookKeepingAndCommissions}`}
          >
            <div className={styles.section}>
              <div>
                <h2>Bookkeeping and Monitoring at its finest </h2>
                <p>
                  Manage all applications and documents in one place. Keep track
                  of application processes and focus on the processes that
                  require your time.
                </p>
                <Link to={Routes.benefits}>Learn more</Link>
              </div>

              <DashboardUISVGV2 />
            </div>
            <div className={styles.section}>
              <FlexibleCommisionsSVGV2 />
              <div>
                <h2>Retain 100% Commission</h2>
                <p>
                  Our transparent commission settings allows you to retain the
                  full commissions from the universities for every successful
                  enrollment.
                </p>
                <Link to={Routes.commissions}>Learn more</Link>
              </div>
            </div>
          </section>
          <section className={styles.professionalClaims}>
            <h1>Agents on Vobb are Professionals</h1>
            <div className={styles.professionalLogosContainer}>
              <img src={britishCouncilImagePNG} alt="british council" />
              <img src={IcefImagePNG} alt="Icef" />
              <img src={ceaImagePNG} alt="cea" />
              <img src={nafsaImagePNG} alt="nafsa" />
            </div>
          </section>
          <GrowthClaims />
          <FeedbackSection customerFeedbacks={CustomerFeedbacks} />
          <PriceSection />
          <ProductivityClaims />
        </div>
      </LandingPageLayout>
      <Toast
        heading="Authorization failed"
        text="Please login to gain access"
        show={toast}
        closeModal={() => setToast(false)}
        type={false}
      />
    </>
  );
};

export default LandingHome;
