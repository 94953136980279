import {
  HomeProductivityIcon_1,
  HomeProductivityIcon_2,
  HomeProductivityIcon_3,
  HomeProductivityIcon_4,
} from "assets/vectors";
import { Link } from "react-router-dom";
import { LandingInfoBox } from "routes/Home/components";
import { Routes } from "utils/routes";
import homePageStyles from "./homePage.module.css";

const ProductivityClaims = () => {
  return (
    <section
      className={`${homePageStyles.homeSection} ${homePageStyles.productivityClaimsSection} landingWrapper`}
    >
      <div className={homePageStyles.productivityClaimsContent}>
        <h2>Future of Productivity with new shape</h2>
        <p>Coming products we are stoked about</p>
        <Link className={homePageStyles.crmLink} to={Routes.crmLanding}>Learn More</Link>
      </div>
      <div className={homePageStyles.productivityClaimsInfoBoxes}>
        <div className={homePageStyles.infoBoxGroup}>
          <LandingInfoBox
            title="CRM"
            Icon={HomeProductivityIcon_1}
            description="Better organize your customer’s data and activities on Vobb."
            styles={{ background: "#EBF8FE" }}
            boxWithShadow
          />
          <LandingInfoBox
            title="Marketing and Analytics"
            Icon={HomeProductivityIcon_3}
            styles={{ background: "#FFEEEB" }}
            description="Use data provided by Vobb to analyze and maximize your marketing."
          />
        </div>
        <div className={homePageStyles.infoBoxGroup}>
          <LandingInfoBox
            title="Email"
            Icon={HomeProductivityIcon_2}
            styles={{ background: "#FFF4CC" }}
            description="Reach out to your prospects directly within your dashboard"
          />
          <LandingInfoBox
            title="Finance"
            Icon={HomeProductivityIcon_4}
            styles={{ background: "#EFFBF5" }}
            description="Keep track of your revenue. Commissions from affiliated institutions, Partners and Students"
          />
        </div>
      </div>
    </section>
  );
};

export default ProductivityClaims;
