import { countries } from "country-flags-svg";
import { getNationality } from "Helper/country";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import { useHistory } from "react-router-dom";
import { StudentAppContext } from "routes/StudentApplicationForm/context";
import { StudentDetailsUpdateTypes } from "routes/StudentApplicationForm/context/types";
import { StudentAppButton } from "../../Button";
import {
  E164Number,
  StudentAppTextInput,
  StudentDateInputField,
  StudentPhoneInput,
} from "../../Input";
import { optionState, SelectBox } from "../../Selectbox";
import { StudentAppFormLayout } from "../layout";
import styles from "./styles.module.css";

const StudentBioForm: React.FC = () => {
  const { state, dispatch } = React.useContext(StudentAppContext);
  const history = useHistory();

  // Local State Variables
  const [gender, setGender] = useState<string>(state.studentBioInfo.gender);
  const [nationality, setNationality] = useState<string>(
    state.studentBioInfo.nationality
  );
  const [dob, setDob] = useState(state.studentBioInfo.dateOfBirth);
  const [mobileNumber, setMobileNumber] = useState<E164Number | undefined>(
    state.studentBioInfo.mobileNumber
  );

  // React hook form hook
  const {
    register,
    // handleSubmit,
    // formState: { errors },
    watch,
  } = useForm({ mode: "onChange", defaultValues: { ...state.studentBioInfo } });

  // Form field values
  const formValues = watch();

  // Gender Selectbox Items
  const GenderSelectboxItems = useMemo<optionState[]>(
    () => [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
    ],
    []
  );
  // Nationality Selectbox Items
  const NationalitySelectboxItems = useMemo<optionState[]>(
    () =>
      countries.map((country) => ({
        value: country.iso3,
        label: getNationality(country.iso3),
      })),
    []
  );

  // Handle gender dropdown item select
  const handleGenderSelect = (dropItem: optionState) => {
    console.log(dropItem);
    setGender(dropItem.value);
  };
  // Handle nationality dropdown item select
  const handleNationalitySelect = (dropItem: optionState) => {
    setNationality(dropItem.value);
  };

  // Checks if all fields are completed
  const areAllFieldsCompleted = useMemo(
    () =>
      Object.keys(state.studentBioInfo).every((value) =>
        value === "passportNumber" ||
        value === "fathersName" ||
        value === "mothersMedian" ||
        value === "address"
          ? true
          : !!state.studentBioInfo[value]
      ),
    [state.studentBioInfo]
  );

  // Handle form submit
  const handleFormSubmit = (e) => {
    if (!areAllFieldsCompleted) return;
    e.preventDefault();
    dispatch({
      type: StudentDetailsUpdateTypes.UpdatePageCompletion,
      payload: {
        ...state.pageCompletionChecks,
        activeApplicationTab: "school",
      },
    });
  };

  // Update context with input field values any time a change occurs
  const {
    address,
    firstname,
    lastname,
    mothersMedian,
    passportNumber,
    studentEmail,
    fathersName,
  } = formValues;

  // Update context when input values change
  useEffect(() => {
    dispatch({
      type: StudentDetailsUpdateTypes.UpdatePageCompletion,
      payload: {
        ...state.pageCompletionChecks,
        student: areAllFieldsCompleted,
      },
    });

    dispatch({
      type: StudentDetailsUpdateTypes.UpdateStudentBio,
      payload: {
        ...state.studentBioInfo,
        ...formValues,
        dateOfBirth: dob,
        gender,
        nationality,
        mobileNumber: String(mobileNumber),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dob,
    address,
    firstname,
    lastname,
    mothersMedian,
    passportNumber,
    studentEmail,
    fathersName,
    gender,
    mobileNumber,
    nationality,
    areAllFieldsCompleted,
  ]);
  console.log(state.studentBioInfo);
  return (
    <StudentAppFormLayout
      header="Student Bio Info"
      subHeader={
        <React.Fragment>
          <p>
            Please fill out your details and{" "}
            <a href="https://www.vobb.io">Vobb.io</a> will direct you to the
            best student enrollment representative to help you build your
            application.
          </p>
        </React.Fragment>
      }
    >
      <form className={styles.bioFormContainer}>
        <h3>Student Information</h3>

        <fieldset className={styles.textInputContainer}>
          <StudentAppTextInput
            id="firstName"
            inputLabel="First Name"
            placeholder="Enter First Name"
            customWrapperClassName={styles.textInput}
            {...register(`firstname`)}
          />
          <StudentAppTextInput
            id="lastName"
            inputLabel="Last Name"
            placeholder="Enter Last Name"
            customWrapperClassName={styles.textInput}
            {...register(`lastname`)}
          />
        </fieldset>
        <fieldset className={styles.textInputContainer}>
          <SelectBox
            placeholder="Select Gender"
            dropdownItems={GenderSelectboxItems}
            selectedItem={{ value: gender, label: gender }}
            selectGroupID="gender"
            onDropItemSelect={handleGenderSelect}
            customContainerClassName={styles.selectBox}
            inputLabel="Gender"
          />

          <StudentDateInputField
            hideDateIndicator
            inputLabel="Date of Birth"
            placeholder="Date of birth"
            value={dob}
            onChange={(event) => setDob(event.target.value)}
            customStyles={{ marginBottom: "24px" }}
          />
        </fieldset>
        <fieldset className={styles.textInputContainer}>
          <StudentAppTextInput
            id="studentEmail"
            inputLabel="Email Address"
            placeholder="Enter email address"
            customWrapperClassName={styles.textInput}
            type="email"
            {...register(`studentEmail`)}
          />

          <StudentPhoneInput
            onChange={(number) => setMobileNumber(number)}
            label="Mobile Number"
            value={mobileNumber}
          />
        </fieldset>
        <fieldset className={styles.textInputContainer}>
          <SelectBox
            placeholder="Select Nationality"
            dropdownItems={NationalitySelectboxItems}
            selectedItem={{ value: nationality, label: getNationality(nationality) }}
            selectGroupID="guesthouse_boys"
            onDropItemSelect={handleNationalitySelect}
            customContainerClassName={styles.selectBox}
            inputLabel="Nationality"
          />

          {/* <StudentAppTextInput
            placeholder="Enter Passport Number"
            inputLabel="Passport Number"
            customWrapperClassName={styles.textInput}
            type="text"
            id="passportNumber"
            {...register(`passportNumber`)}
          /> */}
        </fieldset>
        {/* <fieldset className={styles.textInputContainer}>
          <StudentAppTextInput
            placeholder="Enter Father’s Name"
            inputLabel="Father’s Name"
            customWrapperClassName={styles.textInput}
            type="text"
            id="fathersName"
            {...register(`fathersName`)}
          />
          <StudentAppTextInput
            placeholder="Enter Mother’s Maiden Name"
            inputLabel="Mother’s Maiden Name"
            customWrapperClassName={styles.textInput}
            type="text"
            id="mothersMedian"
            {...register(`mothersMedian`)}
          />
        </fieldset> */}
        {/* <fieldset className={styles.textInputContainer}>
          <StudentAppTextInput
            placeholder="Enter Address"
            inputLabel="Address"
            customWrapperClassName={styles.textInput}
            type="text"
            id="address"
            {...register(`address`)}
          />
        </fieldset> */}
        <aside className={styles.buttonContainer}>
          <StudentAppButton
            label="Cancel"
            buttonType="secondary"
            type="button"
            customClassName={styles.cancelButton}
            onClick={() => history.replace("/prospect/login")}
          />
          <StudentAppButton
            label="Next"
            buttonType="primary"
            type="button"
            onClick={handleFormSubmit}
            disabled={!areAllFieldsCompleted}
          />
        </aside>
      </form>
    </StudentAppFormLayout>
  );
};

export { StudentBioForm };
