import {
  landingAudioAvatarPNG,
  LandingHeroPlayIconSVGV2,
  audioWaveGif
} from "assets";
import React from "react";
import styles from "./styles.module.css";

const LandingAudioPlayer: React.FC = () => {

  return (
    <div className={styles.audioContainer}>
      <span className={styles.audioAvatar}>
        <img src={landingAudioAvatarPNG} alt="audio avatar" />
      </span>
      <span className={styles.audioWaves}>
        {/* <LandingHeroSoundWavesSVGV2 /> */}
        <img src={audioWaveGif} alt="audio" />
      </span>
      <span className={styles.audioPlayIcon}>
        <LandingHeroPlayIconSVGV2 />
      </span>
    </div>
  );
};

export { LandingAudioPlayer };
