import { facebookPNGIcon, googlePNGIcon, twitterPNGIcon } from "assets";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import footerLogo from "../../assets/vectors/vobbIcon.svg";
import axios from "../../requests/axios-base";
import { Routes } from "../../utils/routes";
import Preloader from "../Preloader/preloader";
import Privacy from "../Privacy";
import Terms from "../Terms";
import Toast from "../Toast/toast";
import styles from "./styles.module.css";

const NewFooter = () => {
  const date = new Date().getFullYear();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [modals, setModals] = React.useState({ terms: false, privacy: false });
  const [toast, setToast] = React.useState(false);
  const [toastDetails, setToastDetails] = React.useState({
    toastHeading: "",
    toastText: "",
    toastType: true,
  });
  const { toastHeading, toastText, toastType } = toastDetails;
  const history = useHistory();
  const login = () => {
    history.push(Routes.login);
  };
  const request = () => {
    history.push(Routes.access);
  };
  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };
  const subscribe = (e: any) => {
    if (email) {
      e.preventDefault();
      setLoading(true);
      // console.log(email);
      const data = {
        email: email,
      };
      axios
        .post(`/agents/subscribe`, data)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            setLoading(false);
            openToast(
              "Awesome!",
              "You have successfully subscribed to Vobb updates.",
              true
            );
            setEmail("");
          }
        })
        .catch((error) => {
          // console.log(error.response);
          setLoading(false);
          openToast(
            "Oh no!",
            "Your subscription failed, please try again later.",
            false
          );
        });
    } else {
      openToast("", "Please enter your email", false);
    }
  };
  const openToast = (hd, txt, type) => {
    setToast(true);

    setToastDetails({
      ...toastDetails,
      toastHeading: hd,
      toastText: txt,
      toastType: type,
    });
    setTimeout(() => {
      setToast(false);
    }, 1500);
  };
  return (
    <>
      <Toast
        show={toast}
        heading={toastHeading}
        text={toastText}
        type={toastType}
        closeModal={() => setToast(false)}
      />
      {loading && <Preloader />}
      <footer
        className={`${styles["section-b-footer"]}   my-md-5 py-4 py-md-5 px-4 px-md-0`}
      >
        <div className="container">
          <div
            className={`${styles["footer-box-a"]} row align-items-center justify-content-between `}
          >
            <div className={`${styles.subscribeContainer} col-12 col-lg-6 `}>
              <h4 className="text-center text-md-start">
                Subscribe to our Newsletter
              </h4>
              <p className="text-center text-md-start me-0 me-lg-5">
                You can join our mailing list and be the first to receive
                information about new updates and exciting stuff we are working
                on
              </p>
            </div>
            <div className={`col-12 col-lg-6 px-0`}>
              <div
                className={`${styles["footer-input"]} d-flex justify-content-between py-2`}
              >
                <input
                  value={email}
                  onChange={handleChange}
                  type="email"
                  placeholder="Enter email address"
                />
                <button
                  onClick={subscribe}
                  className="px-3 py-2 px-md-4 py-md-3"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
          <hr className="d-none d-md-block" />
          <div
            className={`${styles["footer-box-b"]} d-flex flex-column flex-lg-row justify-content-between mt-5`}
          >
            <div
              className={`d-flex flex-wrap flex-column ${styles.footerVobbBrief}`}
            >
              <div className="">
                <img className={styles.logo} src={footerLogo} alt="" />
              </div>
              <p className={`${styles.footerCompanyStatement} mt-3`}>
                The all-in-one platform for travel agents.
              </p>
            </div>
            {/* <ul className={`${styles.footerLinksContainer} me-4 mb-4`}>
              <li className={`${styles.footerLinkHeader} mb-4 fw-bold`}>
                Compare
              </li>
              <li
                onClick={() => history.push(Routes.benefits)}
                className={styles.footerLink}
              >
                vs Adventus
              </li>
              <li
                onClick={() => history.push(Routes.newagents)}
                className={styles.footerLink}
              >
                vs Applyboard
              </li>
            </ul> */}
            <ul className={`${styles.footerLinksContainer} me-4 mb-4`}>
              <li className={`${styles.footerLinkHeader} mb-4 fw-bold`}>
                Highlights
              </li>
              {/* <li
                onClick={() => history.push(Routes.affiliatesAndReferrals)}
                className={styles.footerLink}
              >
                Affiliate & Referral
              </li> */}
              <li
                onClick={() => history.push(Routes.commissions)}
                className={styles.footerLink}
              >
                Commissions
              </li>
              <li
                onClick={() => history.push(Routes.benefits)}
                className={styles.footerLink}
              >
                Applications
              </li>
            </ul>
            <ul className={`${styles.footerLinksContainer} me-4 mb-4`}>
              <li className={`${styles.footerLinkHeader} mb-4 fw-bold`}>
                Quick Links
              </li>
              {/* <li onClick={request} className={styles.footerLink}>
                Find Partners
              </li> */}

              <li
                onClick={() => setModals({ ...modals, privacy: true })}
                className={styles.footerLink}
              >
                Privacy Policy
              </li>

              <li
                onClick={() => setModals({ ...modals, terms: true })}
                className={styles.footerLink}
              >
                Terms of use
              </li>
              <li
                onClick={() => history.push(Routes.about)}
                className={styles.footerLink}
              >
                The Team
              </li>
              <li onClick={request} className={styles.footerLink}>
                Get started
              </li>
              {/* <li>
                <Link
                  to={Routes.blog}
                  target="_blank"
                  className={styles.footerLink}
                >
                  Blog
                </Link>
              </li> */}
            </ul>
            <div className={styles.footerLinksWrapper}>
              <ul className="d-flex flex-wrap flex-column">
                <li className={`${styles.footerLinkHeader} mb-4 fw-bold`}>
                  Contacts
                </li>
                <li>Feel free to get in touch with us via email</li>
                <a href="mailto: support@vobb.io" className="d-flex">
                  Email:{" "}
                  <li
                    className={`${styles["e-mail"]} mx-1`}
                    style={{ marginBottom: "0px !important" }}
                  >
                    {" "}
                    support@vobb.io
                  </li>
                </a>
                <li className={`d-none d-lg-inline-block ${styles.socialicon}`}>
                  <span>
                    <a
                      href="https://twitter.com/vobb_io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitterPNGIcon} alt="twitter" />
                    </a>
                  </span>
                  <span className="mx-2">
                    <a
                      href="https://www.facebook.com/VobbSA/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={facebookPNGIcon} alt="facebook" />
                    </a>
                  </span>
                  {/* <span className="mx-2">
                    <img src={googlePNGIcon} alt="google" />
                  </span> */}
                </li>
              </ul>
            </div>
            <div className={`mt-4 d-lg-none ${styles.mobileSocialLinks}`}>
              <span className="mx-2">
                <img src={twitterPNGIcon} alt="twitter" />
              </span>
              <span className="mx-2">
                <img src={facebookPNGIcon} alt="facebook" />
              </span>
              {/* <span className="mx-2">
                <img src={googlePNGIcon} alt="google" />
              </span> */}
            </div>
          </div>
          <hr />
          <h6 className="text-center mt-4">
            © Vobb {date}, All Rights Reserved
          </h6>
        </div>{" "}
      </footer>
      <Terms
        show={modals.terms}
        closeModal={() => setModals({ ...modals, terms: false })}
      />
      <Privacy
        show={modals.privacy}
        closeModal={() => setModals({ ...modals, privacy: false })}
      />
    </>
  );
};

export default NewFooter;
