import Preloader from "components/Preloader/preloader";
import Toast from "components/Toast/toast";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "requests/axios-base";
import { StudentAppContext } from "routes/StudentApplicationForm/context";
import { StudentDetailsUpdateTypes } from "routes/StudentApplicationForm/context/types";
import { StudentAppButton } from "../../Button";
import { FileInput } from "../../FileInput";
import { StudentAppFormLayout } from "../layout";
import styles from "./styles.module.css";

const StudentDocForms: React.FC = () => {
  const { state, dispatch } = React.useContext(StudentAppContext);
  const params = useParams<{ controllingAgentID: string }>();
  const history = useHistory();

  // Local State Variables
  const [showModal, setShowModal] = useState(false);
  const [apiRequestStatus, setAPIRequestStatus] = useState<"idle" | "pending">(
    "idle"
  );
  const [modalMessages, setModalMessages] = useState({
    success: {
      heading: "",
      subText: "",
    },
    fail: {
      heading: "",
      subText: "",
    },
  });
  const [modalType, setModalType] = useState<"hidden" | "success" | "fail">(
    "hidden"
  );

  // File update handler
  const updateUploadedFiles = (files) => {
    dispatch({
      type: StudentDetailsUpdateTypes.UpdateDocuments,
      payload: files,
    });
  };

  // Go back handler
  const handleFormGoBack = () => {
    dispatch({
      type: StudentDetailsUpdateTypes.UpdatePageCompletion,
      payload: {
        ...state.pageCompletionChecks,
        activeApplicationTab: "school",
      },
    });
  };

  // Form submit handler
  const handleFormSubmit = () => {
    // Arrange all files for the request object
    const allFormData = { ...state };
    const name = `${allFormData.studentBioInfo.firstname} ${allFormData.studentBioInfo.lastname}`;
    const university = allFormData.studentSchoolDetails.map((school) => ({
      university: school.university.value,
      degree: school.degree,
      courseOfStudy: school.courseOfStudy,
      additionalNote: school.additionalNote,
    }));
    const files = state.uploadedApplicationFiles;
    const requestObject = { name, files, university, ...state.studentBioInfo };

    // Create a form data
    let requestFormData = new FormData();

    Object.keys(requestObject).forEach((key) => {
      if (key === "university") {
        requestObject[key].forEach((university, idx) => {
          Object.keys(university).forEach((key) => {
            requestFormData.append(
              `university[${idx}][${key}]`,
              university[key]
            );
          });
        });
      } else if (key === "files") {
        const collectedFiles = requestObject["files"];

        collectedFiles.length > 0 &&
          collectedFiles.forEach((file) => {
            requestFormData.append("files", file, file.name);
          });
      } else requestFormData.append(key, requestObject[key]);
    });

    setAPIRequestStatus("pending");

    // API Call to create a application
    axios
      .post(
        `prospect/application/new/${params.controllingAgentID}`,
        requestFormData
      )
      .then((data) => {
        if (data.data) {
          setShowModal(true);
          setModalType("success");
          setModalMessages((currentMessages) => ({
            ...currentMessages,
            success: {
              heading: "Submitted successfully",
              subText:
                "Thank you for submitting your application. Your receiving agent will revert to you shortly. Please check your email for your login details to track your existing applications.",
            },
          }));
        }
      })
      .catch((error) => {
        setShowModal(true);
        setModalType("fail");
        setModalMessages((currentMessages) => ({
          ...currentMessages,
          fail: {
            heading: "Something Failed.",
            subText: error?.response?.data?.message,
          },
        }));
      })
      .finally(() => setAPIRequestStatus("idle"));
  };

  // Modal close handler
  const handleModalClose = () => {
    setShowModal(false);
    if (modalType === "success") {
      history.replace("/prospects/login");
    }
  };

  return (
    <StudentAppFormLayout
      header="School Info"
      subHeader={
        <React.Fragment>
          <p>
            OPTIONAL (You can upload the required documents to help evaluate
            your eligibility to your preferred destinations and other qualified
            destinations)
          </p>
        </React.Fragment>
      }
    >
      <section className={styles.docsFormContainer}>
        <header>
          <h3>Upload Supporting Documents</h3>
          <p>
            Upload document and provide document name (for easy identification).
            <br />
            This is a list of documents which we would ideally require to
            process any application:
          </p>
          <ul>
            <li> International Passport</li>
            <li>A-level result</li>
            <li>CV</li>
            <li>Degree Certificate (If planning for masters)</li>
            <li>Degree Transcript (If planning for masters)</li>
            <li>Academic Reference Letter</li>
            <li>
              Professional Reference Letter (If the student has work experience,
              otherwise a 2nd Academic LOR)
            </li>
            <li>Personal Statements</li>
          </ul>
        </header>

        <form>
          <fieldset className={styles.fieldGroupComponent}>
            <FileInput
              name={`documentFile`}
              label="Drag and drop files here"
              mode="append"
              accept=".pdf,.doc,.jpg, .jpeg, .png"
              inputHeaderTitle={
                <h5 className={styles.docFileInputHeader}>
                  Supporting Documents <span>(Optional)</span>
                </h5>
              }
              defaulValues={state.uploadedApplicationFiles}
              getFiles={updateUploadedFiles}
            />
          </fieldset>
        </form>

        <aside className={styles.buttonContainer}>
          <StudentAppButton
            label="Back"
            buttonType="secondary"
            customClassName={styles.cancelButton}
            type="button"
            onClick={handleFormGoBack}
          />
          <StudentAppButton
            label="Submit Application"
            buttonType="primary"
            type="button"
            onClick={handleFormSubmit}
          />
        </aside>
      </section>

      {apiRequestStatus === "pending" && <Preloader />}

      <Toast
        heading={modalMessages[modalType]?.heading}
        text={modalMessages[modalType]?.subText}
        type={modalType === "success" ? true : false}
        show={showModal}
        closeModal={handleModalClose}
      />
    </StudentAppFormLayout>
  );
};

export { StudentDocForms };
