import { VobbLogoFull } from "assets";
import { useGAEventTracker } from "hooks";
import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Routes } from "utils/routes";
import { Button } from "..";
import landingNavStyles from "./landingNavbar.module.css";
import { LandingNavLinks, NavbarType } from "./NavLinks";

const LandingNavBar: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const GAEventsTracker = useGAEventTracker("Request Access");

  const handleRequestAccess = () => {
    GAEventsTracker("Request access from: ", pathname);
    push(Routes.access);
  };

  // Change navbar color on scroll
  const [changeNavbar, setChangeNavbar] = useState(false);
  useLayoutEffect(() => {
    const handleNavbarBgChange = () => {
      if (window.scrollY > 10) {
        setChangeNavbar(true);
      } else {
        setChangeNavbar(false);
      }
    };

    window.addEventListener("scroll", handleNavbarBgChange);
    return () => window.removeEventListener("scroll", handleNavbarBgChange);
  });

  // Event listener for window resize
  const [navBarType, setNavbarType] =
    useState<keyof typeof NavbarType>("desktop");

  // Effect to handle navbar type update
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1000) {
        setNavbarType("desktop");
      } else setNavbarType("mobile");
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <section className={`${landingNavStyles.backgroundWrapper} ${
      changeNavbar ? landingNavStyles.altNavbar : "" 
    } ${landingNavStyles[navBarType]}`}>
      <section
        className={`${landingNavStyles.mainWrapper} ${
          changeNavbar ? landingNavStyles.altNavbar : ""
        } ${landingNavStyles[navBarType]} landingWrapper`}
      >
        <div className={landingNavStyles.logoWrap}>
        <VobbLogoFull className={landingNavStyles.logo} role="button" onClick={() => push(Routes.home)} />
        <span className={landingNavStyles.logoText} >atlas</span></div>
        <nav
          className={`${landingNavStyles.allLinksWrapper} ${landingNavStyles[navBarType]}`}
        >
          <LandingNavLinks navbarType={navBarType} />
        </nav>
        {navBarType === "desktop" && (
          <div
            className={landingNavStyles.secondaryLinks}
            style={{ display: "flex" }}
          >
            <Link className={landingNavStyles.linkItem} to={Routes.login}>
              Login
            </Link>
            <Button
              onClick={handleRequestAccess}
              label="Get Started"
              colorVariant="blue"
              buttonSize="medium"
            />
          </div>
        )}
      </section>
    </section>
  );
};

export { LandingNavBar };
