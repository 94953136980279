import React, { ButtonHTMLAttributes } from "react";
import styles from "./styles.module.css";

// Button Props
interface StudentAppButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  buttonType: "secondary" | "primary";
  customClassName?: string;
}

const StudentAppButton: React.FC<StudentAppButtonProps> = ({
  label,
  buttonType = "primary",
  customClassName = "",
  ...rest
}) => {
  return (
    <button
      className={`${styles.button} ${styles[buttonType]} ${customClassName}`}
      {...rest}
    >
      {label}
    </button>
  );
};

export { StudentAppButton };
