import React, { InputHTMLAttributes } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import styles from "./styles.module.css";

// Text Input
interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  customStyles?: React.CSSProperties;
  customInputClassName?: string;
  customWrapperClassName?: string;
  inputLabel?: string;
  register?: UseFormRegister<FieldValues>;
}

const StudentAppTextInput: React.FC<InputFieldProps> = React.forwardRef<
  HTMLInputElement,
  InputDateProps
>(
  (
    {
      customStyles,
      customInputClassName = "",
      inputLabel = "label",
      customWrapperClassName,
      register,
      ...rest
    },
    ref
  ) => {
    return (
      <aside
        className={`${styles.textInputContainer} ${customWrapperClassName}`}
      >
        {inputLabel && (
          <label htmlFor={rest.id} className={`${styles.textInputLabel}`}>
            {inputLabel}
          </label>
        )}

        <input
          className={`${styles.textInput} ${customInputClassName}`}
          style={customStyles}
          {...register}
          {...rest}
          ref={ref}
        />
      </aside>
    );
  }
);

// Date Input Field
interface InputDateProps extends InputFieldProps {
  hideDateIndicator?: boolean;
}

const StudentDateInputField: React.FC<InputDateProps> = React.forwardRef<
  HTMLInputElement,
  InputDateProps
>(
  (
    {
      name,
      inputLabel = "Label",
      hideDateIndicator = false,
      register,
      ...rest
    },
    ref
  ) => {
    // Styles to add calendar icon
    const hideIndicatorStyles = hideDateIndicator ? styles.hideIndicator : "";

    return (
      <div className={styles.inputFieldDateContainer} style={rest.customStyles}>
        <label htmlFor={name}>{inputLabel}</label>
        <aside className={styles.inputFieldDateWrapper}>
          {rest.value ? (
            <p className={hideIndicatorStyles}>{rest.value}</p>
          ) : (
            <p className={`${hideIndicatorStyles} ${styles.placeholder}`}>
              {rest.placeholder}
            </p>
          )}
          <span
            className={`${styles.calendarIconContainer} ${hideIndicatorStyles}`}
          ></span>
          <input
            className={hideIndicatorStyles}
            id={name}
            name={name}
            {...register}
            {...rest}
            ref={ref}
            type="date"
          />
        </aside>
      </div>
    );
  }
);

type Tagged<A, T> = A & { __tag?: T };
export type E164Number = Tagged<string, "E164Number">;

// Phone Input Component
interface PhoneInputProps {
  label?: string;
  onChange(value?: E164Number | undefined);
  value?: E164Number | undefined;
}

const StudentPhoneInput: React.FC<PhoneInputProps> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <aside className={styles.phoneInputContainer}>
      {label && <label>{label}</label>}
      <PhoneInput
        international
        countrySelectProps={{ unicodeFlags: true }}
        defaultCountry="IN"
        onChange={(value) => onChange(value)}
        value={value}
        className={styles.phoneInput}
      />
    </aside>
  );
};

// Text Area Input Props
interface TextAreaFieldProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  customStyles?: React.CSSProperties;
  customInputClassName?: string;
  customWrapperClassName?: string;
  limit?: number;
  value?: string;
  inputLabel?: string;
  register?: UseFormRegister<FieldValues>;
}

const StudentTextAreaInput: React.FC<TextAreaFieldProps> = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(
  (
    {
      customStyles,
      customInputClassName = "",
      inputLabel = "label",
      limit,
      customWrapperClassName,
      value = "",
      register,
      ...rest
    },
    ref
  ) => {
    return (
      <aside
        className={`${styles.textInputContainer} ${styles.textAreaInputContainer} ${customWrapperClassName}`}
      >
        {inputLabel && (
          <label htmlFor={rest.id} className={`${styles.textInputLabel}`}>
            {inputLabel}
          </label>
        )}

        <textarea
          className={styles.textArea}
          {...rest}
          {...register}
          ref={ref}
          value={value}
        ></textarea>

        {limit && (
          <p className={styles.textAreaLimit}>
            {value.length}/{limit}
          </p>
        )}
      </aside>
    );
  }
);

export {
  StudentAppTextInput,
  StudentDateInputField,
  StudentPhoneInput,
  StudentTextAreaInput,
};
