import { LandingPriceCardProps } from "../../components/PriceCard/index";
import { AccordionItem, AccordionInfo } from "./Accordion";
import homePageStyles from "./homePage.module.css";
import * as React from "react";

// PriceSection Props
export interface PriceSectionProps {
  subTitle?: string;
  title?: string;
  priceCardsDetails?: LandingPriceCardProps[];
}

const PriceSection = () => {
  const [active, setActive] = React.useState(-1);

  const info: AccordionInfo[] = [
    {
      question: "Document review",
      answer:
        "Our review team immediately examines every document to verify that they meet the requirements, and this ensures a 99% chance of receiving an offer.",
    },
    {
      question: "Frequent updates",
      answer:
        "With a response time of less than 5 minutes, we speedily respond to your requests and also provide frequent updates on new developments.",
    },
    {
      question: "Notice on special offers",
      answer:
        "We ensure that you're the first to know about any scholarships the universities may offer.",
    },
  ];

  return (
    <div className={`${homePageStyles.pricingSectionWrapper}`}>
      <section
        className={`${homePageStyles.homeSection} ${homePageStyles.pricingSection}`}
      >
        <p className={homePageStyles.priceTtl}>Simple, transparent pricing</p>
        <p className={homePageStyles.priceTxt}>No charges. No surpise fees.</p>
        <div className={homePageStyles.priceTxtSec}>
          <p className={homePageStyles.priceTxtSecTxt}>
            When you create a student, you will be required to select a plan for
            them. For every plan, we provide the following services to optimize
            the application process and increase the chances of securing an
            admission for your student
          </p>
          {info.map((item, index) => (
            <AccordionItem
              {...item}
              key={index}
              state={index}
              active={active}
              changeActive={(x) => setActive(x)}
            />
          ))}
        </div>

        <div className={homePageStyles.priceCardWrap}>
          <div className={homePageStyles.priceCard}>
            <p className={homePageStyles.priceCardTtl}>Sapphire</p>
            <p className={homePageStyles.priceCardTxt1}>Free</p>
            <ul className={homePageStyles.services}>
              <li>No application fee</li>
              <li>Up to 3 applications per student</li>
              <li>Agents get 65% commission </li>
            </ul>
          </div>

          <div className={homePageStyles.priceCard}>
            <p className={homePageStyles.priceCardTtl}>Gold</p>
            <p className={homePageStyles.priceCardTxt1}>
              $65<span>per student</span>
            </p>
            <ul className={homePageStyles.services}>
              <li>Top Priority</li>
              <li>Up to 5 applications for the student </li>
              <li>Creation of SOP</li>
              <li>Scholarship applications</li>
              <li>Agents get 100% commission </li>
            </ul>
          </div>
          <div className={homePageStyles.priceCard}>
            <p className={homePageStyles.priceCardTtl}>Ruby</p>
            <p className={homePageStyles.priceCardTxt1}>
              $95<span>per student</span>
            </p>
            <ul className={homePageStyles.services}>
              <li>Top Priority</li>
              <li>Up to 8 applications for the student </li>
              <li>Creation of SOP</li>
              <li>Scholarship applications</li>
              <li>Agents get 100% commission </li>
            </ul>
          </div>
        </div>
        <p className={homePageStyles.note}>Note: Transaction fees may apply</p>
        <div className={homePageStyles.morePriceSec}>
          <p className={homePageStyles.priceCardTxt2}>
            Want to send over 50 applications?
          </p>
          <p className={homePageStyles.priceCardTxt3}>
            Become a platinum user and send applications in bulk!
          </p>
          <a
            className={homePageStyles.priceCardLink}
            target={"_blank"}
            rel="noreferrer"
            href="https://wa.me/+441305237616?text=Hello, I'd like to process over 50 applications, how do I register my students as platinum?"
          >
            Contact Sales
          </a>
        </div>
      </section>
    </div>
  );
};

export default PriceSection;
