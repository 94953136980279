import { useCallback, useContext, useEffect, useState } from "react";
import { StudentAppContext } from "routes/StudentApplicationForm/context";
import {
  StudentDetailsUpdateTypes,
  StudentNavTypes,
} from "routes/StudentApplicationForm/context/types";

export interface NavItemProp {
  title: string;
  id?: string;
  isCompleted?: boolean;
  Component?: React.ReactElement | undefined;
}

const useNav = ({ navItems }: { navItems: NavItemProp[] }) => {
  const [allNavItems, setAllNavItems] = useState(navItems);
  const [currentNavItem, setCurrentNavItem] = useState(() => allNavItems[0]);
  const [doesRouteExist, setDoesRouteExist] = useState(false);

  // State Context
  const { state, dispatch } = useContext(StudentAppContext);

  // Update Current tab handler
  const updateCurrentNavItem = useCallback(
    (tabItem: NavItemProp) => {
      // Current nav key
      const currentNavKey = tabItem.title.toLowerCase() as StudentNavTypes;

      setCurrentNavItem({
        ...tabItem,
        isCompleted: state.pageCompletionChecks[currentNavKey],
      });

      dispatch({
        type: StudentDetailsUpdateTypes.UpdatePageCompletion,
        payload: {
          ...state.pageCompletionChecks,
          activeApplicationTab: currentNavKey,
        },
      });
    },
    [dispatch, state.pageCompletionChecks]
  );

  // Update page completion when completed
  useEffect(() => {
    const updateTabCompletion = () =>
      setAllNavItems((navItems) => {
        return navItems.map((item) => ({
          ...item,
          isCompleted: state.pageCompletionChecks[item.title.toLowerCase()],
        }));
      });

    updateTabCompletion();
  }, [currentNavItem.title, state]);

  // Find a nav item
  const findANavItem = useCallback(
    (tabName: string) =>
      navItems.find(
        (item) => item.title.toLowerCase() === tabName.toLowerCase()
      ),
    [navItems]
  );

  // Update Current Nav when the next button is clicked
  useEffect(() => {
    const updateActiveNav = () => {
      let wouldbeActiveTab = findANavItem(
        state.pageCompletionChecks.activeApplicationTab
      );
      if (wouldbeActiveTab) setCurrentNavItem(wouldbeActiveTab);
      else setCurrentNavItem(navItems[0]);
    };

    updateActiveNav();
  }, [findANavItem, navItems, state.pageCompletionChecks.activeApplicationTab]);

  // Check if a tab is active
  const isActive = useCallback(
    (tabName: string) => currentNavItem.title === tabName,
    [currentNavItem.title]
  );

  // Check if a tab is completed
  const isCompletedTab = useCallback(
    (tabName: string) => findANavItem(tabName)?.isCompleted,
    [findANavItem]
  );

  // Update tab completion
  const updateTabCompleteness = useCallback(
    ({
      tabName,
      tabCompleteStatus,
    }: {
      tabName: string;
      tabCompleteStatus: boolean;
    }) =>
      setAllNavItems((currentNavItems) => {
        const itemToUpdate = findANavItem(tabName);
        return currentNavItems.map((item) => {
          if (item.title.toLowerCase() === itemToUpdate?.title.toLowerCase()) {
            item.isCompleted = tabCompleteStatus;
          }
          return item;
        });
      }),
    [findANavItem]
  );

  return {
    allNavItems,
    updateTabCompleteness,
    currentNavItem,
    doesRouteExist,
    isActive,
    isCompletedTab,
    setCurrentNavItem,
    setDoesRouteExist,
    updateCurrentNavItem,
  };
};

export { useNav };
