import {
  AllActionTypes,
  PageCompletionType,
  StudentAppFilesTypes,
  StudentBioDataProps,
  StudentDetailsUpdateTypes,
  StudentSchoolDetailsProps,
} from "./types";

// Student Bio Reducer
export const StudentBioReducer = (
  state: StudentBioDataProps,
  action: AllActionTypes
) => {
  switch (action.type) {
    case StudentDetailsUpdateTypes.UpdateStudentBio: {
      const updatedStudentBio = {};

      Object.keys(action.payload).forEach((item) => {
        updatedStudentBio[item] = action.payload[item];
      });

      return { ...updatedStudentBio } as StudentBioDataProps;
    }

    default:
      return state;
  }
};

// Student School Details Updater
export const StudentSchoolDetailsReducer = (
  state: StudentSchoolDetailsProps,
  action: AllActionTypes
) => {
  switch (action.type) {
    case StudentDetailsUpdateTypes.UpdateSchoolInfo: {
      return [...action.payload];
    }

    default:
      return state;
  }
};

// Student Documents Updater
export const StudentSchoolDocsReducer = (
  state: StudentAppFilesTypes,
  action: AllActionTypes
) => {
  switch (action.type) {
    case StudentDetailsUpdateTypes.UpdateDocuments: {
      return [...action.payload];
    }
    default:
      return state;
  }
};

// Student Documents Updater
export const PageCompletionReducer = (
  state: PageCompletionType,
  action: AllActionTypes
) => {
  switch (action.type) {
    case StudentDetailsUpdateTypes.UpdatePageCompletion: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};
