import React from "react";
import styles from "./styles.module.css";

interface IProps {
  header: string;
  subHeader: React.ReactElement;
}

const StudentAppFormLayout: React.FC<IProps> = ({
  header = "Create New Application",
  subHeader,
  children,
}) => {
  return (
    <section className={styles.studentBioAppFormContainer}>
      <aside className={styles.bioFormHeaderText}>
        <h2>{header}</h2>
        {subHeader}
      </aside>

      {children}
    </section>
  );
};

export { StudentAppFormLayout };
