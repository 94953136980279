import { BluePlusIcon, DeleteButtonIcon } from "assets";
import axios from "axios";
import { getCountry } from "Helper/country";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "react-phone-number-input/style.css";
import { StudentAppContext } from "routes/StudentApplicationForm/context";
import {
  StudentDetailsUpdateTypes,
  UniversityDataProps,
} from "../../../context/types";
import { StudentAppButton } from "../../Button";
import { StudentAppTextInput, StudentTextAreaInput } from "../../Input";
import { optionState, SelectBox } from "../../Selectbox";
import { StudentAppFormLayout } from "../layout";
import styles from "./styles.module.css";

// Util Function to create an Array
export function generateUID() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  let firstPart: number | string = (Math.random() * 46656) | 0;
  let secondPart: number | string = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
}

const StudentSchoolDetailsForm: React.FC = () => {
  const { state, dispatch } = useContext(StudentAppContext);

  // Form size
  const [formFields, setFormFields] = useState<UniversityDataProps[]>(
    state.studentSchoolDetails
  );

  // Country Selectbox Items
  const [CountryListItems, setCountryListItems] = useState<optionState[]>([
    { label: "Loading...", value: "" },
  ]);

  // University Selectbox Items
  const [CompleteUniversityList, setCompleteUniversityList] = useState<any[]>([
    { title: "Loading..." },
  ]);

  // University Selectbox Items
  const [UniversityListItems, setUniversityListItems] = useState<optionState[]>(
    [{ label: "Loading...", value: "" }]
  );

  // Fetch and update available universities
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/applications/available-universities`
      )
      .then((data) => {
        if (data.data) {
          let listItem: optionState[] = [];

          data.data.data.neededUniveristy.map((university) => {
            if (!listItem.some((item2) => item2.value === university.country)) {
              listItem.push({
                label: getCountry(university.country),
                value: university.country,
              });
            }
          });

          listItem.sort((a, b) => a.label.localeCompare(b.label));
          setCountryListItems(listItem);

          setCompleteUniversityList(() =>
            data.data.data.neededUniveristy.map((university) => ({
              title: university.university,
              country: university.country,
              id: university._id,
            }))
          );

          setUniversityListItems(
            data.data.data.neededUniveristy.map((university) => ({
              label: university.university,
              value: university._id,
            }))
          );
        }
      });
  }, []);

  // Degree Selectbox Items
  const DegreeListItems = useMemo<optionState[]>(
    () => [
      { label: "Undergraduate", value: "undegraduate" },
      { label: "Masters", value: "masters" },
      { label: "PhD", value: "phd" },
    ],
    []
  );

  // School details delete
  const handleSchoolDetailsDelete = (id: string) => {
    const newFields = formFields.filter((field) => field.id !== id);
    setFormFields(newFields);
    dispatch({
      type: StudentDetailsUpdateTypes.UpdateSchoolInfo,
      payload: newFields,
    });
  };

  // Handle Input Value Update
  const handleInputChange = ({
    event,
    dropItem,
    universityDetails,
    name,
  }: {
    dropItem?: optionState;
    universityDetails: UniversityDataProps;
    name?: string;
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  }) => {
    event?.preventDefault();

    setFormFields((allCurrentFields) => {
      const selectedFieldGroup = allCurrentFields.find(
        (field) => field.id === universityDetails.id
      );

      if (selectedFieldGroup && event) {
        selectedFieldGroup[event.target.name] = event.target.value;

        return allCurrentFields.map((field) => {
          if (selectedFieldGroup.id === field.id) {
            return selectedFieldGroup;
          }

          return field;
        });
      }

      if (selectedFieldGroup && name && dropItem) {
        if (name === "universityCountry") {
          selectedFieldGroup["university"] = { value: "", label: "" };
          let listItem: optionState[] = [];

          CompleteUniversityList.map((item) => {
            if (
              item.country?.toLowerCase() === dropItem.value?.toLowerCase() &&
              !listItem.some((item2) => item2.value === item?.id)
            ) {
              listItem.push({
                label: item.title,
                value: item.id,
              });
            }
          });

          listItem.sort((a, b) => a.label.localeCompare(b.label));
          setUniversityListItems(listItem);
        }

        selectedFieldGroup[name] =
          name === "degree" ? dropItem.value : dropItem;

        return allCurrentFields.map((field) => {
          if (selectedFieldGroup.id === field.id) {
            return selectedFieldGroup;
          }

          return field;
        });
      }

      dispatch({
        type: StudentDetailsUpdateTypes.UpdateSchoolInfo,
        payload: allCurrentFields,
      });

      console.log(allCurrentFields);
      return allCurrentFields;
    });
  };

  // Check if all fields are filled
  const allFieldsAreIntact = useMemo(
    () =>
      formFields.every(
        (value) =>
          Object.keys(value).length >= 2 &&
          Object.keys(value).every((key) =>
            key === "additionalNote" ? true : !!value[key]
          )
      ),
    [formFields]
  );

  // Update Page Completion Context value
  useEffect(() => {
    dispatch({
      type: StudentDetailsUpdateTypes.UpdatePageCompletion,
      payload: {
        ...state.pageCompletionChecks,
        school: allFieldsAreIntact,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFieldsAreIntact, dispatch]);

  // Go back handler
  const handleFormGoBack = () => {
    dispatch({
      type: StudentDetailsUpdateTypes.UpdatePageCompletion,
      payload: {
        ...state.pageCompletionChecks,
        activeApplicationTab: "student",
      },
    });

    dispatch({
      type: StudentDetailsUpdateTypes.UpdateSchoolInfo,
      payload: formFields,
    });
  };

  // Add new field to the existing form
  const handleAddNewField = () => {
    setFormFields((allCurrentForm) => {
      const newFields = [
        ...allCurrentForm,
        {
          university: { value: "", label: "" },
          universityCountry: { value: "", label: "" },
          id: generateUID(),
          degree: "",
          additionalNote: "",
          courseOfStudy: "",
        },
      ];

      dispatch({
        type: StudentDetailsUpdateTypes.UpdateSchoolInfo,
        payload: newFields,
      });

      return newFields;
    });
  };

  // Handle Form Submit
  const handleFormSubmit = () =>
    dispatch({
      type: StudentDetailsUpdateTypes.UpdatePageCompletion,
      payload: {
        ...state.pageCompletionChecks,
        activeApplicationTab: "documents",
        documents: true,
      },
    });

  return (
    <StudentAppFormLayout
      header="Application Documents"
      subHeader={
        <React.Fragment>
          <p>
            Please, indicate your preferred study abroad destinations to receive
            tailored counseling. You can choose more than one study destination
          </p>
        </React.Fragment>
      }
    >
      <form className={styles.bioFormContainer}>
        {formFields.map((universityDetails, idx) => {
          // Form Values
          const {
            id,
            courseOfStudy,
            university,
            degree,
            additionalNote,
            universityCountry,
          } = universityDetails;

          return (
            <section className={styles.textInputWrapper} key={idx}>
              <h3>
                University Information {formFields.length > 1 ? `(${idx + 1})` : ""}
              </h3>

              <fieldset className={styles.textInputContainer}>
                {formFields.length > 1 && (
                  <DeleteButtonIcon
                    className={styles.deleteButton}
                    onClick={() => id && handleSchoolDetailsDelete(id)}
                  />
                )}

                <SelectBox
                  placeholder="Select Country of Interest"
                  dropdownItems={CountryListItems}
                  selectedItem={universityCountry}
                  selectGroupID={`university_${universityDetails}`}
                  onDropItemSelect={(dropItem) =>
                    handleInputChange({
                      dropItem,
                      universityDetails,
                      name: "universityCountry",
                    })
                  }
                  customContainerClassName={styles.selectBox}
                  inputLabel="Country of Interest"
                />

                <SelectBox
                  placeholder="Select Preferred University"
                  dropdownItems={UniversityListItems}
                  selectedItem={university}
                  selectGroupID={`university_${universityDetails}`}
                  onDropItemSelect={(dropItem) =>
                    handleInputChange({
                      dropItem,
                      universityDetails,
                      name: "university",
                    })
                  }
                  customContainerClassName={styles.selectBox}
                  inputLabel="University"
                />

                <SelectBox
                  placeholder="Select Degree"
                  dropdownItems={DegreeListItems}
                  selectedItem={{ value: degree, label: degree }}
                  selectGroupID={`degree_${idx}`}
                  onDropItemSelect={(dropItem) =>
                    handleInputChange({
                      dropItem,
                      universityDetails,
                      name: "degree",
                    })
                  }
                  customContainerClassName={styles.selectBox}
                  inputLabel="Degree"
                />

                <StudentAppTextInput
                  placeholder="Enter Preferred Course of Study"
                  inputLabel="Course of Study"
                  customWrapperClassName={styles.textInput}
                  type="text"
                  id={`courseOfStudy_${idx}`}
                  name="courseOfStudy"
                  value={courseOfStudy}
                  onChange={(event) =>
                    handleInputChange({
                      universityDetails,
                      event,
                    })
                  }
                />

                <StudentTextAreaInput
                  placeholder="Additional notes"
                  inputLabel="Additonal Note"
                  customWrapperClassName={styles.textInput}
                  type="text"
                  limit={250}
                  id={`additionalNote_${idx}`}
                  name="additionalNote"
                  value={additionalNote}
                  onChange={(event) =>
                    handleInputChange({
                      universityDetails,
                      event,
                    })
                  }
                />
              </fieldset>
            </section>
          );
        })}

        <button
          className={styles.addUniButton}
          type="button"
          onClick={handleAddNewField}
        >
          <BluePlusIcon /> Add University
        </button>

        <aside className={styles.buttonContainer}>
          <StudentAppButton
            label="Back"
            buttonType="secondary"
            customClassName={styles.cancelButton}
            type="button"
            onClick={handleFormGoBack}
          />
          <StudentAppButton
            label="Next"
            buttonType="primary"
            type="button"
            disabled={!allFieldsAreIntact}
            onClick={handleFormSubmit}
          />
        </aside>
      </form>
    </StudentAppFormLayout>
  );
};

export { StudentSchoolDetailsForm };
