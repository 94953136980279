import { SelectedFileCloseIcon, SelectedFilesIcon } from "assets";
import Toast from "components/Toast/toast";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import styles from "./styles.module.css";

// File input props
interface IProps {
  inputHeaderTitle?: React.ReactElement;
  name: string;
  label: string;
  mode: "update" | "append";
  accept?: string | undefined;
  containerCustomClassName?: string;
  getFiles?: (files, name: string) => void;
  defaulValues: any;
}

const FileInput: React.FC<IProps> = (props) => {
  // State Variables
  const { inputHeaderTitle, name, mode = "update" } = props;
  const { register, unregister, setValue, watch } = useForm({
    mode: "onChange",
    defaultValues: { [name]: props.defaulValues },
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    header: "",
    subText: "",
  });
  const files = watch(name);

  // Close modal handler
  const handleModalClose = () => setShowModal(false);

  // Close modal logic after a certain time frame
  const closeModalTimer = useCallback((timeInMs: number) => {
    setTimeout(() => {
      handleModalClose();
    }, timeInMs);
  }, []);

  // Ondrop logic for file attachment
  const onDrop = useCallback(
    (droppedFiles) => {
      let newFiles =
        mode === "update" ? droppedFiles : [...(files || []), ...droppedFiles];

      if (mode === "append") {
        const maxFileAttachment = newFiles.length > 12;

        if (maxFileAttachment) {
          setShowModal(true);
          closeModalTimer(5000);
          setModalMessage({
            header: "File Attachment Exceeded",
            subText:
              "You can only attach a maximum of 12 files for this application.",
          });
          return;
        }

        newFiles = newFiles.reduce((prev, file) => {
          const fo = Object.entries(file);

          console.log("files", file);

          // Stop File Addition
          const fileIsBiggerThanNeeded = file.size / 1024 / 1024 > 1;

          if (fileIsBiggerThanNeeded) {
            setShowModal(true);
            closeModalTimer(5000);
            setModalMessage({
              header: "File Size Error",
              subText:
                "Attempting to attach a file greater than 1MB. Reduce size and try again.",
            });
          }

          if (
            fileIsBiggerThanNeeded ||
            prev.find((e) => {
              const eo = Object.entries(e);
              return eo.every(
                ([key, value], index) =>
                  key === fo[index][0] && value === fo[index][1]
              );
            })
          ) {
            return prev;
          } else {
            return [...prev, file];
          }
        }, []);
      }
      setValue(name, newFiles, { shouldValidate: true });
      props.getFiles?.(newFiles, name);
    },
    [mode, files, setValue, name, props, closeModalTimer]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept,
  });

  // File delete handler
  const handleFilesDelete = (fileToBeDeleted) => {
    let existingFiles = files;

    const updatedFiles = existingFiles.filter(
      (file) => file.name !== fileToBeDeleted.name
    );

    setValue(name, updatedFiles, { shouldValidate: true });
    props.getFiles?.(updatedFiles, name);
  };

  // Form input register
  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return (
    <div
      className={`${styles.selectFilesMainContainer}${props.containerCustomClassName}`}
    >
      {inputHeaderTitle}
      <div
        {...getRootProps()}
        className={`${styles.dragContainer} ${
          isDragActive ? styles.active : ""
        }`}
      >
        <button type="button" className={styles.selectFilesButton}>
          Select Files
          <input {...props} id={name} {...getInputProps()} />
        </button>
        <p>Drag and drop files here or Click to choose file</p>
      </div>
      <div className={styles.uploadInfo}>
        <p>
          You can upload a total of 12 files at a time.{" "}
          <span className={styles.red}>(max. size of 1MB each)</span>
        </p>
        <p>
          The supported formats are: <span>.doc, .png, .jpg</span>, and{" "}
          <span>.pdf</span>
        </p>
      </div>

      {!!files?.length && (
        <ul className={styles.fileList}>
          {files.map((file) => {
            // Resolved file name
            const fileName =
              file.name.length > 15
                ? `${file.name.slice(0, 15)}...`
                : file.name;

            return (
              <li
                key={file.name}
                className={styles.fileListItem}
                title={file.name}
              >
                <SelectedFilesIcon className={styles.fileListItemIcon} />
                {fileName}
                <SelectedFileCloseIcon
                  className={styles.fileListItemCloseIcon}
                  onClick={() => handleFilesDelete(file)}
                />
              </li>
            );
          })}
        </ul>
      )}
      <Toast
        heading={modalMessage.header}
        text={modalMessage.subText}
        type={false}
        show={showModal}
        closeModal={handleModalClose}
      />
    </div>
  );
};

export { FileInput };
