import { CaretDownGrey, HamburgerMenuIcon, NavbarCloseIcon } from "assets";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "utils/routes";
import linksGroupStyles from "./landingNavbar.module.css";

export enum NavbarType {
  mobile = "mobile",
  desktop = "desktop",
}

interface NavbarProps {
  navbarType: keyof typeof NavbarType;
}

// Navbar link group
const LandingNavLinks: React.FC<NavbarProps> = ({ navbarType }) => {
  const [showNavbar, setShowNavbar] = useState(false);
  // Navbar Links
  const NavbarLinks = useMemo<LinkItem[]>(
    () => [
      // {
      //   title: "Product",
      //   link: "",
      //   dropdownItems: [
      //     { title: "Applications", link: Routes.benefits },
      //     { title: "Commissions", link: Routes.commissions },
      //   ],
      // },
      // {
      //   title: "Affiliate & Refferrals",
      //   link: Routes.affiliatesAndReferrals,
      // },
      // {
      //   title: "Find Partners",
      //   link: Routes.newagents,
      // },
      { title: "Home", link: Routes.crmLanding },
      { title: "Applications", link: Routes.benefits },
      { title: "Commissions", link: Routes.commissions },
      {
        title: "The Team",
        link: Routes.about,
      },
      // { title: "Compare", link: Routes.comparisonPage },
      // { title: "Blog", link: Routes.blog },
    ],
    []
  );

  const MobileNavbarLinks = useMemo<LinkItem[]>(
    () => [
      // {
      //   title: "Product",
      //   link: "",
      //   dropdownItems: [
      //     { title: "Applications", link: Routes.benefits },
      //     { title: "Commissions", link: Routes.commissions },
      //   ],
      // },
      { title: "Home", link: Routes.crmLanding },
      { title: "Applications", link: Routes.benefits },
      { title: "Commissions", link: Routes.commissions },
      // {
      //   title: "Affiliate & Refferrals",
      //   link: Routes.affiliatesAndReferrals,
      // },
      // {
      //   title: "Find Partners",
      //   link: Routes.newagents,
      // },
      {
        title: "The Team",
        link: Routes.about,
      },
      // { title: "Compare", link: Routes.comparisonPage },
      // { title: "Blog", link: Routes.blog },
      { title: "Login", link: Routes.login },
      { title: "Get started", link: Routes.access },
    ],
    []
  );

  // Load Navbar handler
  const handleLoadNavbar = () => setShowNavbar((navbarState) => !navbarState);

  return (
    <React.Fragment>
      {navbarType === "mobile" && showNavbar ? (
        <React.Fragment>
          <ul
            className={`${linksGroupStyles.linksContainer} ${linksGroupStyles[navbarType]}`}
          >
            {MobileNavbarLinks.map((linkItem) => {
              return <NavLinkItem {...linkItem} navbarType={navbarType} />;
            })}
          </ul>
        </React.Fragment>
      ) : navbarType === "mobile" ? (
        <HamburgerMenuIcon onClick={handleLoadNavbar} />
      ) : null}

      {showNavbar && (
        <NavbarCloseIcon
          style={{ transform: "scale(2)" }}
          onClick={handleLoadNavbar}
        />
      )}

      {navbarType === "desktop" && (
        <ul
          className={`${linksGroupStyles.linksContainer} ${linksGroupStyles[navbarType]}`}
        >
          {NavbarLinks.map((linkItem) => {
            return <NavLinkItem {...linkItem} navbarType={navbarType} />;
          })}
        </ul>
      )}
    </React.Fragment>
  );
};

// Link item props
interface LinkItem {
  title: string;
  link: string;
  target?: string;
  dropdownItems?: { title: string; link: string }[];
  navbarType?: keyof typeof NavbarType;
}

const NavLinkItem: React.FC<LinkItem> = ({
  title,
  link,
  target,
  dropdownItems,
  navbarType,
}) => {
  const [activeDropDownState, setActiveDropdownState] = useState(false);
  const dropdownLinkRef = useRef<HTMLLIElement | null>(null);
  const dropdownRef = useRef<HTMLUListElement | null>(null);

  // Effect to close dropdown when a click outside happens
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        dropdownLinkRef.current &&
        !dropdownLinkRef.current.contains(e.target) &&
        !dropdownLinkRef.current.contains(e.target)
      ) {
        setActiveDropdownState(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef, dropdownLinkRef]);

  return (
    <React.Fragment>
      <li
        ref={dropdownLinkRef}
        key={title}
        className={`
        ${linksGroupStyles.linkItem} 
        ${navbarType ? linksGroupStyles[navbarType] : ""}
        `}
        onClick={() => setActiveDropdownState((activeState) => !activeState)}
      >
        <div>
          {!dropdownItems ? (
            <Link to={link} target={target}>
              {title}
            </Link>
          ) : (
            title
          )}
          {dropdownItems && (
            <CaretDownGrey
              className={`
            ${linksGroupStyles.caretDown} 
            ${navbarType ? linksGroupStyles[navbarType] : ""}
            `}
            />
          )}
        </div>

        {activeDropDownState && dropdownItems?.length && (
          <ul
            ref={dropdownRef}
            className={`
            ${linksGroupStyles.dropdownList}
            ${navbarType ? linksGroupStyles[navbarType] : ""}
          `}
          >
            {dropdownItems?.map((dropItem) => {
              return (
                <li
                  key={dropItem.title}
                  className={`
                  ${linksGroupStyles.dropListItem}
                  ${navbarType ? linksGroupStyles[navbarType] : ""}
                `}
                >
                  <Link to={dropItem.link}>{dropItem.title}</Link>
                </li>
              );
            })}
          </ul>
        )}
      </li>
    </React.Fragment>
  );
};

export { LandingNavLinks };
