import React from "react";
import feedbackCardStyles from "./feedbackCard.module.css";

// Feedback Card Props
export interface FeedbackProps {
  feedback: string;
  customerName: string;
  affiliation?: string;
}

const FeedbackCard: React.FC<FeedbackProps> = ({
  feedback,
  customerName,
  affiliation,
}) => {
  return (
    <div className={feedbackCardStyles.mainContainer}>
      <p>{feedback}</p>
      <h4>{customerName}</h4>
      {affiliation && <h6>{affiliation}</h6>}
    </div>
  );
};

export { FeedbackCard };
