import React, { createContext, Dispatch, useReducer } from "react";
import { generateUID } from "../components";
import {
  PageCompletionReducer,
  StudentBioReducer,
  StudentSchoolDetailsReducer,
  StudentSchoolDocsReducer,
} from "./reducers";
import { AllActionTypes, InitialStudentFormStateType } from "./types";

// Initial Student Application State
const initialState: InitialStudentFormStateType = {
  studentBioInfo: {
    firstname: "",
    lastname: "",
    gender: "",
    dateOfBirth: "",
    studentEmail: "",
    mobileNumber: "",
    nationality: "",
    passportNumber: "",
    mothersMedian: "",
    fathersName: "",
    address: "",
  },

  studentSchoolDetails: [
    {
      university: { value: "", label: "" },
      universityCountry: { value: "", label: "" },
      degree: "",
      courseOfStudy: "",
      additionalNote: "",
      id: generateUID(),
    },
  ],
  uploadedApplicationFiles: [],
  pageCompletionChecks: {
    student: false,
    school: false,
    documents: false,
    activeApplicationTab: "student",
  },
};

// Student application context
const StudentAppContext = createContext<{
  state: InitialStudentFormStateType;
  dispatch: Dispatch<AllActionTypes>;
}>({
  state: initialState,
  dispatch: () => null,
});

// Main Reducer function
const mainReducer = (
  {
    studentBioInfo,
    studentSchoolDetails,
    uploadedApplicationFiles,
    pageCompletionChecks,
  }: InitialStudentFormStateType,
  action: AllActionTypes
) => ({
  studentBioInfo: StudentBioReducer(studentBioInfo, action),
  studentSchoolDetails: StudentSchoolDetailsReducer(
    studentSchoolDetails,
    action
  ),
  uploadedApplicationFiles: StudentSchoolDocsReducer(
    uploadedApplicationFiles,
    action
  ),
  pageCompletionChecks: PageCompletionReducer(pageCompletionChecks, action),
});

// Student Application Provider
const StudentAppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <StudentAppContext.Provider value={{ state, dispatch }}>
      {children}
    </StudentAppContext.Provider>
  );
};

export { StudentAppProvider, StudentAppContext };
