import { RejectSVGIcon } from "assets";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

interface TermsProps {
  closeModal: () => void;
  show: boolean;
}

const Terms: React.FC<TermsProps> = ({ closeModal, show }) => {
  return (
    <>
      <Modal
        className={styles["v-terms"]}
        show={show}
        onHide={closeModal}
        centered
      >
        <RejectSVGIcon
          className={styles.svg}
          onClick={closeModal}
          title="close"
          role="button"
        />
        <Modal.Title>TERMS AND CONDITIONS OF USE</Modal.Title>
        <Modal.Body>
          <div>
            <h6>Introduction</h6>
            <p>
              These terms and conditions apply between you, the User of this
              Website (including any sub-domains, unless expressly excluded by
              their own terms and conditions), and Vobb ltd, the owner and
              operator of this Website. Please read these terms and conditions
              carefully, as they affect your legal rights. Your agreement to
              comply with and be bound by these terms and conditions is deemed
              to occur upon your first use of the Website. If you do not agree
              to be bound by these terms and conditions, you should stop using
              the Website immediately. In these terms and conditions, User or
              Users means any third party that accesses the Website and is not
              either:
              <ul className={styles["v-terms-roman"]}>
                <li>
                  employed by Vobb, ltd and acting in the course of their
                  employment or
                </li>
                <li>
                  engaged as a consultant or otherwise providing services to
                  Vobb, ltd and accessing the Website in connection with the
                  provision of such services.
                </li>
              </ul>
              You must be at least 18 years of age to use this Website. By using
              the Website and agreeing to these terms and conditions, you
              represent and warrant that you are at least 18 years of age.
            </p>
          </div>
          <div>
            <h6>Intellectual property and acceptable use</h6>
            <ol>
              <li>
                All Content included on the Website, unless uploaded by Users,
                is the property of Vobb, ltd, our affiliates or other relevant
                third parties. In these terms and conditions, Content means any
                text, graphics, images, audio, video, software, data
                compilations, page layout, underlying code and software and any
                other form of information capable of being stored in a computer
                that appears on or forms part of this Website, including any
                such content uploaded by Users. By continuing to use the Website
                you acknowledge that such Content is protected by copyright,
                trademarks, database rights and other intellectual property
                rights. Nothing on this site shall be construed as granting, by
                implication, estoppel, or otherwise, any license or right to use
                any trademark, logo or service mark displayed on the site
                without the owner's prior written permission
              </li>
              <li>
                You may, for your own personal, non-commercial use only, do the
                following:
                <ul className={styles["v-terms-alpha"]}>
                  <li>
                    retrieve, display and view the Content on a computer screen
                  </li>
                  <li>print one copy of the Content</li>
                </ul>
              </li>
              <li>
                You must not otherwise reproduce, modify, copy, distribute or
                use for commercial purposes any Content without the written
                permission of Vobb, ltd.
              </li>
              <li>
                You acknowledge that you are responsible for any Content you may
                submit via the Website, including the legality, reliability,
                appropriateness, originality and copyright of any such Content.
                You may not upload to, distribute or otherwise publish through
                the Website any Content that
                <ol>
                  <li>
                    is confidential, proprietary, false, fraudulent, libellous,
                    defamatory, obscene, threatening, invasive of privacy or
                    publicity rights, infringing on intellectual property
                    rights, abusive, illegal or otherwise objectionable;{" "}
                  </li>
                  <li>
                    may constitute or encourage a criminal offence, violate the
                    rights of any party or otherwise give rise to liability or
                    violate any law; or{" "}
                  </li>
                  <li>
                    may contain software viruses, political campaigning, chain
                    letters, mass mailings, or any form of "spam." You may not
                    use a false email address or other identifying information,
                    impersonate any person or entity or otherwise mislead as to
                    the origin of any content. You may not upload commercial
                    content onto the Website.
                  </li>
                </ol>
              </li>
              <li>
                You represent and warrant that you own or otherwise control all
                the rights to the Content you post; that the Content is
                accurate; that use of the Content you supply does not violate
                any provision of these terms and conditions and will not cause
                injury to any person; and that you will indemnify Vobb, ltd for
                all claims resulting from Content you supply.
              </li>
              <h6>Prohibited use</h6>
              <li>
                You may not use the Website for any of the following purposes:
                <ul className={styles["v-terms-alpha"]}>
                  <li>
                    in any way which causes, or may cause, damage to the Website
                    or interferes with any other person's use or enjoyment of
                    the Website;
                  </li>
                  <li>
                    in any way which is harmful, unlawful, illegal, abusive,
                    harassing, threatening or otherwise objectionable or in
                    breach of any applicable law, regulation, governmental
                    order;
                  </li>
                  <li>
                    making, transmitting or storing electronic copies of Content
                    protected by copyright without the permission of the owner.
                  </li>
                </ul>
              </li>
              <h6>Registration</h6>
              <li>
                You must ensure that the details provided by you on registration
                or at any time are correct and complete.
              </li>
              <li>
                You must inform us immediately of any changes to the information
                that you provide when registering by updating your personal
                details to ensure we can communicate with you effectively.
              </li>
              <li>
                We may suspend or cancel your registration with immediate effect
                for any reasonable purposes or if you breach these terms and
                conditions.
              </li>
              <li>
                You may cancel your registration at any time by informing us in
                writing to the address at the end of these terms and conditions.
                If you do so, you must immediately stop using the Website.
                Cancellation or suspension of your registration does not affect
                any statutory rights
              </li>
              <h6>Password and security</h6>
              <li>
                When you register on this Website, you will be asked to create a
                password, which you should keep confidential and not disclose or
                share with anyone.
              </li>
              <li>
                If we have reason to believe that there is or is likely to be
                any misuse of the Website or breach of security, we may require
                you to change your password or suspend your account.
              </li>
              <h6>Links to other websites</h6>
              <li>
                This Website may contain links to other sites. Unless expressly
                stated, these sites are not under the control of Vobb, ltd or
                that of our affiliates.
              </li>
              <li>
                We assume no responsibility for the content of such Websites and
                disclaim liability for any and all forms of loss or damage
                arising out of the use of them.
              </li>
              <li>
                The inclusion of a link to another site on this Website does not
                imply any endorsement of the sites themselves or of those in
                control of them.
              </li>
              <h6>Privacy Policy and Cookies Policy</h6>
              <li>
                Use of the Website is also governed by our Privacy Policy and
                Cookies Policy, which are incorporated into these terms and
                conditions by this reference. To view the Privacy Policy, please
                click on the following: <Link to="">privacy-policy-link</Link>.
              </li>
              <h6>Availability of the Website and disclaimers</h6>
              <li>
                Any online facilities, tools, services or information that Vobb,
                ltd makes available through the Website (the Service) is
                provided "as is" and on an "as available" basis. We give no
                warranty that the Service will be free of defects and/or faults.
                To the maximum extent permitted by the law, we provide no
                warranties (express or implied) of fitness for a particular
                purpose, accuracy of information, compatibility and satisfactory
                quality. Vobb, ltd is under no obligation to update information
                on the Website.
              </li>
              <li>
                Whilst Vobb, ltd uses reasonable endeavours to ensure that the
                Website is secure and free of errors, viruses and other malware,
                we give no warranty or guaranty in that regard and all Users
                take responsibility for their own security, that of their
                personal details and their computers.
              </li>
              <li>
                Vobb, ltd accepts no liability for any disruption or
                non-availability of the Website
              </li>
              <li>
                Vobb, ltd reserves the right to alter, suspend or discontinue
                any part (or the whole of) the Website including, but not
                limited to, any products and/or services available. These terms
                and conditions shall continue to apply to any modified version
                of the Website unless it is expressly stated otherwise.
              </li>
              <h6>Limitation of liability</h6>
              <li>
                Nothing in these terms and conditions will:
                <ol className={styles["v-terms-alpha"]}>
                  <li>
                    limit or exclude our or your liability for death or personal
                    injury resulting from our or your negligence, as applicable;{" "}
                  </li>
                  <li>
                    limit or exclude our or your liability for fraud or
                    fraudulent misrepresentation; or{" "}
                  </li>
                  <li>
                    limit or exclude any of our or your liabilities in any way
                    that is not permitted under applicable law.
                  </li>
                </ol>
              </li>
              <li>
                To the extent that the Website and Content are provided free of
                charge, we will not be liable to you for any loss or damage of
                any kind.
              </li>
              <li>
                We will not be liable to you in respect of any losses arising
                out of events beyond our reasonable control.
              </li>
              <li>
                To the maximum extent permitted by law, Vobb, ltd accepts no
                liability for any of the following:
                <ul className={styles["v-terms-alpha"]}>
                  <li>
                    any business losses, such as loss of profits, income,
                    revenue, anticipated savings, business, contracts, goodwill
                    or commercial opportunities;
                  </li>
                  <li>loss or corruption of any data, database or software;</li>
                  <li>
                    any special, indirect or consequential loss or damage.
                  </li>
                </ul>
              </li>
              <h6>General</h6>
              <li>
                You may not transfer any of your rights under these terms and
                conditions to any other person. We may transfer our rights under
                these terms and conditions where we reasonably believe your
                rights will not be affected.
              </li>
              <li>
                These terms and conditions may be varied by us from time to
                time. Such revised terms will apply to the Website from the date
                of publication. Users should check the terms and conditions
                regularly to ensure familiarity with the then current version.
              </li>
              <li>
                These terms and conditions together with the Privacy Policy and
                Cookies Policy contain the whole agreement between the parties
                relating to its subject matter and supersede all prior
                discussions, arrangements or agreements that might have taken
                place in relation to the terms and conditions.
              </li>
              <li>
                The Contracts (Rights of Third Parties) Act 1999 shall not apply
                to these terms and conditions and no third party will have any
                right to enforce or rely on any provision of these terms and
                conditions.
              </li>
              <li>
                If any court or competent authority finds that any provision of
                these terms and conditions (or part of any provision) is
                invalid, illegal or unenforceable, that provision or
                part-provision will, to the extent required, be deemed to be
                deleted, and the validity and enforceability of the other
                provisions of these terms and conditions will not be affected.
              </li>
              <li>
                Unless otherwise agreed, no delay, act or omission by a party in
                exercising any right or remedy will be deemed a waiver of that,
                or any other, right or remedy.
              </li>
              <li>
                This Agreement shall be governed by and interpreted according to
                the law of England and Wales and all disputes arising under the
                Agreement (including non-contractual disputes or claims) shall
                be subject to the exclusive jurisdiction of the English and
                Welsh courts.
              </li>
              <h6>Vobb, ltd details</h6>
              <li>
                Vobb, ltd is a company incorporated in England and Wales with
                registered number 13305815 whose registered address is 71-75
                Shelton Street Covent Garden, London, WC2H 9JQ and it operates
                the Website www.vobb.io.
                <br />
                You can contact Vobb, ltd by email on support@vobb.io.
              </li>
              <h6>Attribution</h6>
              <li>
                These terms and conditions were created using a document from
                <a target="blank" href="https://www.rocketlawyer.co.uk">
                  Rocket Lawyer
                </a>
              </li>
            </ol>
          </div>
          <button onClick={closeModal}>I have read and accepted</button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Terms;
