import * as React from "react";
import styles from "./styles.module.css";
import LandingPageLayout from "routes/Home/layout";
import Pagination from "components/Pagination/pagination";
import { ArrowRight } from "assets";
import axios from "requests/axios-base";
import Preloader from "components/Preloader/preloader";

interface BlogProps {
  date: string;
  title: string;
  description: string;
  link: string;
  image: string;
}

const OneThirdBlog: React.FC<BlogProps> = ({
  date,
  title,
  description,
  link,
  image,
}) => {
  return (
    <div className={styles.sectionTwoItem}>
      <img className={styles.sectionTwoImg} src={image} alt="" />
      <div className={`${styles.sectionOneInfo} ${styles.sectionTwoInfo}`}>
        <p className={styles.sectionOneTxt1}>
          {/* <span>Front-end </span> */}
          {date}
        </p>
        <h5 className={styles.sectionOneHd}>{title}</h5>
        <p className={styles.sectionOneTxt2}>{description}</p>
        <div className={styles.sectionOneTxt3}>
          {/* <span>12 Min Read</span> */}
          <span>
            <a href={link} target="_blank">
              Read Full <ArrowRight />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
interface FullBlogProps extends BlogProps {
  reverse: boolean;
}
const FullBlog: React.FC<FullBlogProps> = ({
  date,
  title,
  description,
  link,
  image,
  reverse,
}) => {
  return (
    <div className={`${styles.sectionOne} ${reverse && styles.reverseRow}`}>
      <img className={styles.sectionOneImg} src={image} alt="" />
      <div className={styles.sectionOneInfo}>
        <p className={styles.sectionOneTxt1}>
          {/* <span>Front-end </span> . */}
          {date}
        </p>
        <h5 className={styles.sectionOneHd}>{title}</h5>
        <p className={styles.sectionOneTxt2}>{description}</p>
        <div className={styles.sectionOneTxt3}>
          {/* <span>3 Min Read</span> */}
          <span>
            <a href={link} target="_blank">
              Read Full <ArrowRight />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

const HalfBlog: React.FC<BlogProps> = ({
  date,
  title,
  description,
  link,
  image,
}) => {
  return (
    <div className={styles.sectionFourItem}>
      <img className={styles.sectionTwoImg} src={image} alt="" />
      <div className={`${styles.sectionOneInfo} ${styles.sectionTwoInfo}`}>
        <p className={styles.sectionOneTxt1}>
          {/* <span>Front-end </span> .  */}
          {date}
        </p>
        <h5 className={styles.sectionOneHd}> {title}</h5>
        <p className={styles.sectionOneTxt2}>{description}</p>
        <div className={styles.sectionOneTxt3}>
          {/* <span>12 Min Read</span> */}
          <span>
            <a href={link} target="_blank">
              Read Full <ArrowRight />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

const Blog = () => {
  const [blogList, setblogList] = React.useState<BlogProps[]>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [pages, setPages] = React.useState<number>(1);
  const [loading, setLoading] = React.useState(false);

  const getBlogs = () => {
    setLoading(true);
    axios
      .get(`/admin/blogs/all?pageNo=${currentPage}&noOfRequests=13`)
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          const fetchedBlogs = response?.data.data.fetchedRequests;
          setPages(response.data.data.availablePages);
          setblogList(
            fetchedBlogs.map((item) => ({
              title: item.title,
              date: item.createdAt.split("T")[0],
              link: item.blog_link,
              image: item.image,
              description: item.description,
            }))
          );
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
        window.scrollTo(0, 0);
      });
  };

  React.useEffect(() => {
    getBlogs();
  }, [currentPage]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <LandingPageLayout>
          {blogList.length > 0 ? (
            <div className={`appContainer ${styles.blog}`}>
              <FullBlog {...blogList[0]} reverse={false} />

              {blogList.length >= 2 && (
                <div className={styles.sectionTwo}>
                  {blogList.slice(1, 7).map((item, index) => (
                    <OneThirdBlog {...item} key={index} />
                  ))}
                </div>
              )}

              {blogList.length >= 8 && (
                <FullBlog {...blogList[7]} reverse={true} />
              )}

              {blogList.length >= 9 && (
                <div className={`${styles.sectionTwo} ${styles.doubleGrid}`}>
                  {blogList.slice(8, 10).map((item, index) => (
                    <HalfBlog {...item} key={index} />
                  ))}
                </div>
              )}
              {blogList.length >= 10 && (
                <div className={styles.sectionTwo}>
                  {blogList.slice(10).map((item, index) => (
                    <OneThirdBlog {...item} key={index} />
                  ))}
                </div>
              )}
              <Pagination
                pages={pages}
                handlePage={(x) => setCurrentPage(x)}
                currentPage={currentPage}
              />
            </div>
          ) : (
            ""
          )}
        </LandingPageLayout>
      )}
    </>
  );
};

export default Blog;
