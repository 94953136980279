import React from "react";
import { Link } from "react-router-dom";
import buttonStyles from "./button.module.css";

// Button color variants
enum ButtonColorVariants {
  blue = "blue",
  green = "green",
}

// Button sizes
enum ButtonSizes {
  small = "small",
  medium = "medium",
}

// Button properties
interface ButtonProps {
  label: string;
  onClick?: () => void;
  colorVariant: keyof typeof ButtonColorVariants;
  buttonSize: keyof typeof ButtonSizes;
  linkType?: boolean;
  buttonLink?: string;
  customStyle?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  colorVariant,
  buttonSize,
  linkType,
  buttonLink = "",
  customStyle,
}) => {
  return (
    <button
      style={customStyle}
      onClick={onClick}
      className={`${buttonStyles.buttonMain} ${buttonStyles[colorVariant]} ${buttonStyles[buttonSize]}`}
    >
      {linkType ? <Link to={`${buttonLink}`}>{label}</Link> : label}
    </button>
  );
};

export { Button };
