import { RepairIcon } from "assets";
import * as React from "react";
import styles from "./styles.module.css";
import { BannerImg } from "assets/images/banners";

const Banner = () => {
  return (
    <div className={styles.container}>
      <img src={BannerImg} />
    </div>
  );
};

export { Banner };
