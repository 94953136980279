import React from "react";
import { Link } from "react-router-dom";
import infoBoxStyles from "./landingInfoBox.module.css";

// Landing Card Props
export interface LandingInfoBoxProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  linkTitle?: string;
  boxLink?: string;
  styles?: React.CSSProperties;
  boxWithShadow?: boolean;
  fullWidth?: boolean;
}

const LandingInfoBox: React.FC<LandingInfoBoxProps> = ({
  Icon,
  title,
  description,
  boxLink,
  linkTitle = "Start",
  styles,
  boxWithShadow,
  fullWidth,
}) => {
  return (
    <div
      className={`
      ${infoBoxStyles.boxContainer} 
      ${!boxLink ? infoBoxStyles.boxWithoutLink : ""}
      ${boxWithShadow ? infoBoxStyles.boxWithShadow : ""}
      ${fullWidth ? infoBoxStyles.fullWidth : ""}
      `}
      style={styles}
    >
      <Icon className={infoBoxStyles.icon} />
      <h3>{title}</h3>
      <p>{description}</p>
      {boxLink && <Link to={boxLink}>{linkTitle}</Link>}
    </div>
  );
};

export { LandingInfoBox };
