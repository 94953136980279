import React from "react";
import NewFooter from "../../../components/newFooter/index";
import { LandingNavBar } from "../components";
import styles from "./landingLayout.module.css";

const LandingPageLayout: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <LandingNavBar />
      <section className={styles.font}>{children}</section>
      <NewFooter />
    </React.Fragment>
  );
};

export default LandingPageLayout;
