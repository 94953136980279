import { LandingHeroBGV2 } from "assets";
import { Button } from "routes/Home/components";
import styles from "./homePage.module.css";
import Typewriter from "typewriter-effect";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "utils/routes";
import { useGAEventTracker } from "hooks";

const HeroSection = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const GAEventsTracker = useGAEventTracker("Request Access");

  const handleRequestAccess = () => {
    GAEventsTracker("Request access from: ", pathname);
    push(Routes.access);
  };
  return (
    <section className={styles.backgroundWrapper}>
      <section
        className={`${styles.heroContainer} ${styles.homeSection} landingWrapper`}
      >
        <aside className={styles.heroContent}>
          <h1>
            The all-in-one and easy-to-use platform for
            <Typewriter
              options={{
                strings: [
                  " Student Recruiters",
                  " Study Abroad Agents",
                  " Travel Agents",
                  " Visa Agents",
                  " Education Consultants",
                ],
                autoStart: true,
                loop: true,
                delay: 50,
              }}
            />
          </h1>
          <p>
            Vobb Atlas is an application portal enabling education consultants
            access and process applications to universities around the world.
          </p>
          <div></div>
          <Button
            onClick={handleRequestAccess}
            label="Get Started"
            colorVariant="blue"
            buttonSize="medium"
          />
        </aside>
        <div className={styles.heroImageContainer}>
          <LandingHeroBGV2 />
        </div>
      </section>
    </section>
  );
};

export default HeroSection;
