import { LeftArrowSVGV2, RightArrowSVGV2 } from "assets";
import React, { useRef } from "react";
import styles from "./styles.module.css";

interface IProps {
  questions: { question: string; answer: string }[];
}

const FAQ: React.FC<IProps> = ({ questions }) => {
  const faqRef = useRef<HTMLElement>(null);

  const scrollFAQs = (scrollOffset) => {
    if (faqRef.current) {
      faqRef.current.scrollLeft += scrollOffset;
    }
  };

  return null;

  return (
    <section className={styles.faqBackground}>
      <section className={`${styles.faqMainContainer}`}>
        <h1>FAQs</h1>
        <aside className={styles.faqQuestionsContainer} ref={faqRef}>
          {questions.map((faq) => (
            <div className={styles.faqQuestion}>
              <div className={styles.faqQuestionHeader}>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#75CFFA" />
                    <path
                      d="M11.4321 16.0327C10.6472 16.0327 10.0111 15.3966 10.0111 14.6117V12.7169C10.0111 11.932 10.6472 11.2959 11.4321 11.2959C11.7177 11.293 14.2742 10.8222 14.2742 8.92745C14.2742 7.98008 13.3268 7.03271 11.9058 7.03271C10.7495 7.03271 10.0111 7.98008 10.0111 8.45377C10.0111 9.23866 9.37489 9.87482 8.59 9.87482C7.8051 9.87482 7.16895 9.23866 7.16895 8.45377C7.16895 6.14314 9.33842 4.19061 11.9058 4.19061C15.6953 4.19061 17.1163 7.02419 17.1163 9.40114C17.1163 11.3646 16.0377 12.8652 14.0786 13.6269C13.6523 13.7927 13.2307 13.9049 12.8532 13.9807V14.6117C12.8532 15.3966 12.217 16.0327 11.4321 16.0327Z"
                      fill="white"
                    />
                    <path
                      d="M12.8528 18.4012C12.8528 19.1861 12.2167 19.8223 11.4318 19.8223C10.6469 19.8223 10.0107 19.1861 10.0107 18.4012C10.0107 17.6163 10.6469 16.9802 11.4318 16.9802C12.2167 16.9802 12.8528 17.6163 12.8528 18.4012Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <h3>{faq.question}</h3>
              </div>
              <p>{faq.answer}</p>
            </div>
          ))}
        </aside>

        <div className={styles.feedbackNavArrow}>
          <LeftArrowSVGV2
            className={`${false ? styles.disabled : ""}`}
            onClick={() => scrollFAQs(-600)}
          />
          <RightArrowSVGV2
            className={`${false ? styles.disabled : ""}`}
            onClick={() => scrollFAQs(600)}
          />
        </div>
      </section>
    </section>
  );
};

export default FAQ;
